import React, { useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { Download } from 'react-feather';
import authAxios from "utils/authAxios";
import { connect } from "react-redux";
import { generateWillRevision as generateWillRevisionAction, getWills as getWillsAction } from "redux/actions/wills";
import segmentTrack from "utils/SegmentIO";


const WillDownload = ({user, buttonText, will, getWills, generateWillRevision, ...props}) => {

  const [updating, setUpdating] = useState(false)

  const handleClick = async() => {
    setUpdating(true)
    if(will.changed && will.valid_summary['all']){
      await generateWillRevision()
    }

    const FileSaver = require('file-saver')
      authAxios().get('wills/download', {
      responseType: 'arraybuffer',
      })
			.then(response => {
			  segmentTrack("MIW | Downloaded Islamic will")
				const blob = new Blob([response.data], {
      type: 'application/pdf',
				});

				FileSaver.saveAs(blob, `Wahed Wills - ${user.full_name} - ${new Date().toLocaleDateString().replace('/', '').replace('/', '')}.pdf`);
		});

    await getWills()
    setUpdating(false)

  }
  return (
    <Button type="submit" variant="contained" color="primary" onClick={handleClick} disableElevation  disabled={updating} startIcon={updating? <CircularProgress size={25} /> : <Download size={20}/>} {...props}>{buttonText}</Button>
  )
}

const mapStateToProps = ({ account, wills }) => ({
  user: account.user,
  will: wills.wills[0]
});


const mapDispatchToProps = dispatch => ({
  getWills: () => dispatch(getWillsAction()),
  generateWillRevision: (postSuccessHook) => dispatch(generateWillRevisionAction(postSuccessHook)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WillDownload);


