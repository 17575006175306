import React, { useEffect } from 'react';
import { Box, Button, Grid, Hidden, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Form, Formik } from "formik";
import { connect } from 'react-redux';
import { ChevronRight } from 'react-feather';
import { partialUpdateAccount as partialUpdateAccountAction } from "redux/actions/account";
import { getWills as getWillsAction, partialUpdateWill as partialUpdateWillAction } from "redux/actions/wills";
import { getAssets as getAssetsAction } from 'redux/actions/assets'
import { routes } from "routes";
import AddAssetDialog from "components/molecules/AddAssetDialog/AddAssetDialog";
import AssetBox from "components/molecules/AssetBox/AssetBox";
import { push } from "connected-react-router";
import WillSectionStepper from "components/organisms/Stepper/WillSectionStepper";
import { sectionSlug, sectionSteps } from "views/will/AccountsAndProperty/Steps";
import SectionResolver from "utils/SectionsResolver";
import MobileHelpText from "components/molecules/HelpText/MobileText";
import StandardHelpText from "components/molecules/HelpText/StandardHelpText";

const stepIndex = 0
const [sectionName, section] = SectionResolver(sectionSteps, stepIndex)

const AccountsAndProperty = ({ user, assets, getAssets, will, getWills, redirectTo, partialUpdateAccount, partialUpdateWill }) => {

  useEffect(() => {
    getAssets()
  }, [getAssets])

  const helpText = "" +
    "<p>Listing your assets will help whoever deals with your Islamic will find everything.</p>" +
    "<p>Here you can include bank accounts, pensions, property and even gold.</p>" +
    "<p>We won't ask for your account details nor how much money you have. We just need to know the names of each provider.</p>"

  return (
    <>
    <Helmet>
      <title>{section["label"]}</title>
      <meta name="segment-event-name" content="MIW | Viewed accounts page" />
    </Helmet>
    <Grid container direction="row" spacing={5}>
      <Hidden smDown>
        <Grid item sm={12}  xs={12} md={4}>
          <WillSectionStepper activeStepIndex={stepIndex} steps={sectionSteps} valid={will.valid[sectionSlug]}/>
        </Grid>
      </Hidden>
      <Grid item sm={12} md={4}>
        <Hidden mdUp>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={4}>
              <Box mb={3}>
                <WillSectionStepper activeStepIndex={stepIndex} steps={sectionSteps} valid={will.valid[sectionSlug]}/>
              </Box>
            </Grid>
          </Grid>
        </Hidden>
        <Typography variant="h1" align="center">{section["label"]}</Typography>
        <MobileHelpText text={helpText} collapsable={true} />
        <p></p>
        <Formik
          initialValues={{ }}
          enableReinitialize={true}
          onSubmit={async (values) => {
            let progress = will.progress
            progress[sectionSlug][sectionName] = true
            await partialUpdateWill(will.id, {'progress': progress})
            redirectTo(routes.will)
          }}
        >
          {({isSubmitting, touched, errors}) => {
            return (
              <Form>
               {assets.map((asset) => (
                 <Box mb={1}>
                   <AssetBox
                     key={asset.id}
                     asset={asset}
                   />
                 </Box>
               ))}

                <AddAssetDialog
                  dialogButtonText="Add asset"
                  user={user}
                  userAssets={assets}
                />
                <Box mt={2} mb={2}>
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} fullWidth>Save and continue <ChevronRight size={20}/></Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </Grid>
      <Hidden smDown>
        <Grid item sm={12} xs={12} md={4}>
          <Box mt={10}>
            <StandardHelpText text={helpText}/>
          </Box>
        </Grid>
      </Hidden>
    </Grid>
  </>
  )
};

const mapStateToProps = ({ account, asset, global, wills }) => ({
  user: account.user,
  assets: asset.assets,
  will: wills.wills[0],
  formError: global.formError,
});

const mapDispatchToProps = dispatch => ({
  getAssets : () => dispatch(getAssetsAction()),
  partialUpdateAccount: (formData) => dispatch(partialUpdateAccountAction(formData, routes.will)),
  getWills: () => dispatch(getWillsAction()),
  partialUpdateWill: (willID, formData) => dispatch(partialUpdateWillAction(willID, formData)),
  redirectTo: (url) => dispatch(push(url))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountsAndProperty);
