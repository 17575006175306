import React, { useEffect, useRef } from "react";
import * as yup from 'yup'
import { connect } from 'react-redux';
import { Link as RouterLink } from "react-router-dom";
import { routes } from "routes";
import { Helmet } from "react-helmet";
import { register as registerAction } from 'redux/actions/auth/Register';
import { Form, Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Typography
} from '@material-ui/core';
import TextField from 'components/atoms/TextField/TextField'
import PrivacyNote from "../../components/molecules/PrivacyNote/PrivacyNote";
import styled from "styled-components";
import { theme } from "theme/mainTheme";
import { enqueueSnackbar as enqueueSnackbarAction } from "redux/actions/snackbar";
import segmentTrack from "utils/SegmentIO";


const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiTypography-body1{
    font-family: ${theme.typography.fontFamilySecondary};
  }
  
`

const Register = ({register, formError, enqueueSnackbar}) => {

  const formikRef = useRef(null);

  useEffect(() => {
    formikRef.current.setErrors(formError)
    window.analytics.track("CNW | Registration | Viewed page", {
      'has_spouse': localStorage["question: Do you have a spouse?"] === "Yes",
      'has_children': localStorage["question: Do you have any children?"] === "Yes",
      'has_home': localStorage["question: Do you own your home?"] === "Yes",
      'lives_in_eow': localStorage["question: Do you live in England or Wales?"] === "Yes",
      'assets_in_uk': localStorage["question: Is everything you own in the UK?"] === "Yes"
    })
  })

  return (
    <>
      <Helmet>
      <title>Bismillah! Let's begin with the basics</title>
    </Helmet>
      <Grid container direction="row" justify="center" alignItems="center" >
       <Grid item md={4} sm={8} >
         <Typography variant="h1" align="center" >Bismillah! Let's start with the basics</Typography>
         <p align="center" className="font-secondary">People in your position typically finish their will in 15 minutes</p>

         <br/>
         <Formik
            innerRef={formikRef}
            initialValues={{
              preferredName: '',
              email: '',
              password: '',
              subscribe: false,
              terms: false,
            }}
            validationSchema= {
              yup.object().shape({
              preferredName: yup.string()
                .label('Preferred Name')
                .required(),
              email: yup.string()
                .label('Email')
                .required()
                .email(),
              password: yup.string()
                .label('Password')
                .required()
                .min(10),
              terms: yup.boolean().oneOf([true], 'You must agree to privacy policy and terms of service'),
            })}
            enableReinitialize={true}
            onSubmit={async ( values, { setSubmitting, setErrors}) => {
              await register(values.email, values.password, values.preferredName, values.subscribe)
              segmentTrack("CNW | Registration | Submitted form", {
                'is_subscribed': values.subscribe,
                'attempts': formikRef.current.submitCount
              })
            }}
         >
            {({ isSubmitting,setFieldValue, values, touched, errors }) => {
              return (
                <Form >
                  <div>
                    <TextField name="preferredName" label="Preferred Name" fullWidth />
                  </div>
                  <div>
                    <TextField name="email" label="Email" fullWidth />
                  </div>
                  <div>
                    <TextField name="password" type="password" label="Password" helpText="Minimum 10 characters" fullWidth />
                  </div>

                   <StyledFormControlLabel
                    label="Email me occasional tips, offers and updates."
                    control={
                      <Checkbox name="subscribe" color="primary" onClick={() => setFieldValue('subscribe', !values.subscribe)}/>
                    }
                  />
                  <StyledFormControlLabel
                    label={
                      <div>
                        I have read and agree to the  set in the <a href="https://truewills.co.uk/privacy" target="_blank" className="MuiLink-root" rel="noopener noreferrer">privacy policy</a> and <a href="https://truewills.co.uk/terms" target="_blank" className="MuiLink-root" rel="noopener noreferrer">terms of service</a>.
                      </div>
                    }
                    control={
                      <Checkbox name="terms" color="primary" onClick={() => setFieldValue('terms', !values.terms)}/>
                    }
                  />
                  <div>
                    <br/>
                    <Button type="submit" variant="contained" color="primary" disabled={!values.terms || isSubmitting } fullWidth > Register </Button>
                  </div>
                </Form>
              )
            }}
         </Formik>
         <Box textAlign="center" mt={3} mb={2} >
           <Typography align="center" className="font-secondary"> Already have a Wahed Wills account?  <Link component={RouterLink} to={{pathname: routes.login}}>Log In</Link></Typography>
         </Box>
         <PrivacyNote/>
       </Grid>
     </Grid>
    </>

)};


const mapStateToProps = ({ global }) => ({
  formError: global.formError
});

const mapDispatchToProps = dispatch => ({
  register: (email, password, preferredName, subscribe) => dispatch(registerAction(email, password, preferredName, subscribe)),
  enqueueSnackbar: (notification) => dispatch(enqueueSnackbarAction(notification, dispatch)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Register);

