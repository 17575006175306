import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { activation as activationAction } from 'redux/actions/auth/Activation';
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import { LockOpen } from '@material-ui/icons';

const Activation = ({ activation }) => {
  const { uid, token } = useParams();

  useEffect(() => {
    activation(uid, token)
  }, [activation, uid, token])

  return (
    <>
      <Helmet>
        <title>Account Activation</title>
      </Helmet>
       <Grid container direction="row" justify="center" alignItems="center" >
         <Grid item md={4} sm={8} >
           <Typography variant="h1" align="center" ><LockOpen /> Account Activation </Typography>
         </Grid>
       </Grid>
    </>
)};


const mapDispatchToProps = dispatch => ({
  activation: (uid, token) => dispatch(activationAction(uid, token)),
});


export default connect(
  null,
  mapDispatchToProps,
)(Activation);

