import React, { useEffect } from "react";
import _ from 'lodash';
import { Box, Grid, Typography } from "@material-ui/core";
import { Mail } from 'react-feather';
import Paper from "components/atoms/Paper/Paper";
import styled from "styled-components";
import { connect } from "react-redux";
import { getConfig as getConfigAction } from "redux/actions/config";

const StyledContactBox = styled(Box)`
  .MuiSvgIcon-root {
    margin-top: 50px;
  }
  
  .MuiTypography-root {
    margin-left: 20px;
    margin-top: 5px;
    font-size: 1.8rem;
  }
`

const ContactBox = ({ config, getConfig }) => {
  useEffect(() => {
    if (_.isEmpty(config)) {
      getConfig();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle1"><b>Speak to a team member</b></Typography>
        </Grid>
        <Grid item xs={12}>
          {config?.contact_email && (
            <StyledContactBox display="flex" justifyContent="left">
              <Mail size={40}/> <Typography className="font-secondary">If you have questions or run into technical issues, our team is only a message away <a href={`mailto:${config.contact_email}`} className="MuiLink-root MuiLink-underlineHover">{config.contact_email}</a></Typography>
            </StyledContactBox>
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}

const mapStateToProps = ({ config }) => ({
  config: config,
});

const mapDispatchToProps = dispatch => ({
  getConfig: () => dispatch(getConfigAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactBox);

