import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import { List as MUIList } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Menu, Plus, List, User, Settings, HelpCircle, LogOut } from 'react-feather';
import { Box, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { routes } from "routes";
import styled from "styled-components";
import { theme } from "theme/mainTheme";
import { ReactComponent as Logo } from "images/logo-wahed.svg";
import { logout as logoutAction } from "redux/actions/auth/Login";
import { connect } from "react-redux";
import segmentTrack from "utils/SegmentIO";


const StyledList = styled(MUIList) `
  width: 250px;
  
  .MuiLink-root{
    color: ${theme.palette.text.primary}!important;
  }
  
  .active {
    color: ${theme.palette.link.main};
  }
`

const StyledMenuWrapper = styled.div `
  margin-left: -15px;

`


const MobileMenu = ({loggedIn, logout, router}) => {
  const [state, setState] = React.useState();

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    segmentTrack("Menu | Clicked dropdown")
    setState(open);
  };

  const handleLogOut = (event) => {
    setTimeout(() => {
      logout()
    }, 100);
  }

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <StyledList>
        <Box mb={3} p={1} display="flex" justifyContent="center">
          <Logo width={180}/>
        </Box>
        {!loggedIn?
          <>
            <ListItem  button className={{'active': router.location.pathname.includes('/bismillah/')}} component={RouterLink} to={routes.start}>
              <ListItemText primary="Create new will" />
            </ListItem>
            <ListItem button className={{'active': router.location.pathname.includes(routes.login)}} component={RouterLink} to={routes.login}>
              <ListItemText primary="Log in" />
            </ListItem>
          </>
          :
          <>
            <ListItem button className={{'active': router.location.pathname.includes(routes.summary)}} component={RouterLink} to={routes.summary}>
              <List size={20}/> &nbsp;
              <ListItemText primary="Summary" />
            </ListItem>
            <ListItem button className={{'active': router.location.pathname.includes(routes.will)}} component={RouterLink} to={routes.will}>
              <User size={20}/> &nbsp;
              <ListItemText primary="My Islamic will" />
            </ListItem>
            <ListItem button className={{'active': router.location.pathname.includes(routes.invite)}} component={RouterLink} to={routes.invite}>
              <Plus size={20}/> &nbsp;
              <ListItemText primary="Invite" />
            </ListItem>
            <Box mt={2} mb={2}>
              <Divider/>
            </Box>
            <ListItem button onClick={() => segmentTrack("Menu | Clicked manage account")} className={{'active': router.location.pathname.includes(routes.account)}} component={RouterLink} to={routes.account}>
              <Settings size={20}/> &nbsp;
              <ListItemText primary="Manage account" />
            </ListItem>
            <ListItem button onClick={() => segmentTrack("Menu | Clicked help")} component={Link} href="https://truewills.co.uk/faqs/" target="_blank">
              <HelpCircle size={20}/> &nbsp;
              <ListItemText primary="Help" />
            </ListItem>
            <ListItem button onClick={() => {segmentTrack("Menu | Clicked save and log out"); handleLogOut();}}>
              <LogOut size={20}/> &nbsp;
              <ListItemText primary="Save and Log out" />
            </ListItem>

          </>
        }

      </StyledList>
    </div>
  );

  return (
    <StyledMenuWrapper>
          <Button onClick={toggleDrawer(true)}><Menu /></Button>
          <Drawer anchor="left" open={state} onClose={toggleDrawer( false)}>
            {list()}
          </Drawer>
    </StyledMenuWrapper>
  );
}


const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutAction()),
});


export default connect(
  null,
  mapDispatchToProps
)(MobileMenu);
