import React, { useEffect, useRef } from 'react';
import _ from "lodash"
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { connect } from 'react-redux';
import { routes } from "routes";
import { theme } from "theme/mainTheme";
import { partialUpdateAccount as partialUpdateAccountAction } from "redux/actions/account";
import { useHistory } from 'react-router-dom';
import { getConfig as getConfigAction } from "redux/actions/config";
import SelectField from "components/atoms/SelectField/SelectField";
import styled from "styled-components";


const Overlay = styled.div`
  position: fixed;
  left: 0;
  top:0;
  width: 100%;
  height: 100%;
  z-index: 999px;
  background-color: ${theme.palette.background.default};
`

const StyledGrid = styled(Grid)`
  z-index: 10;
`

const Referred = ({ user, getConfig, config, partialUpdateAccount }) => {

  const formikRef = useRef(null);
  let history = useHistory();

  useEffect(() => {

    if(user.referred){
      history.push(routes.summary);
    }
    if(_.isEmpty(config)){
        getConfig()
    }
  }, [config, getConfig, history, user.referred])

  return (

     <><Overlay/>
    <Helmet><title>How did you hear about us?</title></Helmet>

     <StyledGrid container direction="row" justify="center" alignItems="center" >
       <Grid item md={5} sm={8} >
         {!_.isEmpty(config) &&  <Typography variant="h1" align="center">How did you hear about us?</Typography>}
        <Formik
          innerRef={formikRef}
          initialValues={{
              referred: user.referred,
            }}
          enableReinitialize={true}
          validationSchema={
            yup.object().shape({
              referred: yup.string()
                .label('Referred')
                .required(),
            })}
          onSubmit={async (values, { resetForm }) => {
            await partialUpdateAccount(values)
          }}
        >
          {({isSubmitting, values, errors}) => {
            return (
               !_.isEmpty(config) && <>
                <Form>
                  <Box mt={5} mb={2}>
                    <SelectField
                      name="referred"
                      values={config.referred}
                      fullWidth
                    />
                  </Box>
                  <Box mb={6}>
                    <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} fullWidth>Submit</Button>
                  </Box>
                </Form>
              </>
            )
          }}
        </Formik>
      </Grid>
    </StyledGrid>
  </>
  )
};

const mapStateToProps = ({ account, config }) => ({
  user: account.user,
  config: config
});

const mapDispatchToProps = dispatch => ({
  getConfig: () => dispatch(getConfigAction()),
  partialUpdateAccount: (formData) => dispatch(partialUpdateAccountAction(formData, routes.summary)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Referred);
