import * as actions from 'redux/actions/config/types'
import authAxios from "utils/authAxios";
import axios from 'axios'

export const getConfig = () => {
  return {
    types: [
      actions.GET_CONFIG_START,
      actions.GET_CONFIG_SUCCESS,
      actions.GET_CONFIG_ERROR
    ],
    callAPI: () => axios.get(`${process.env.REACT_APP_BACKEND_URL}/config/`),
  }
}

