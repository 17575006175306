import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router'
import _ from 'lodash'
import { Box, Button, CircularProgress, Grid, Hidden, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { connect } from 'react-redux';
import { ChevronRight } from 'react-feather';
import SelectField from "components/atoms/SelectField/SelectField";
import {
  getChildren as getChildrenAction,
  getGuardians as getGuardiansAction,
  getPeople as getPeopleAction,
  getPartners as getPartnersAction,
  linkGuardian as linkGuardianAction,
  unlinkGuardian as unlinkGuardianAction,
  deleteAllChildren as deleteAllChildrenAction
} from "redux/actions/people";
import { enqueueSnackbar as enqueueSnackbarAction } from 'redux/actions/snackbar'
import AddChildDialog from "components/molecules/AddChildDialog/AddChildDialog";
import WillSectionStepper from "components/organisms/Stepper/WillSectionStepper";
import { sectionSlug, sectionSteps } from "views/will/AboutYou/Steps";
import { getWills as getWillsAction, partialUpdateWill as partialUpdateWillAction } from "redux/actions/wills";
import SectionResolver from "utils/SectionsResolver";
import ChildListItem from "components/molecules/ChildListItem/ChildListItem";
import PersonListItem from "components/molecules/PersonListItem/PersonListItem";
import AddPersonDialog from "components/molecules/AddPersonDialog/AddPersonDialog";
import MobileHelpText from "components/molecules/HelpText/MobileText";
import StandardHelpText from "components/molecules/HelpText/StandardHelpText";

const stepIndex = 3
const [sectionName, section, nextSection] = SectionResolver(sectionSteps, stepIndex)


const AboutYouChildren = (
  {
    user,
    children,
    getChildren,
    deleteAllChildren,
    partner,
    getPartners,
    people,
    getPeople,
    guardians,
    getGuardians,
    linkGuardian,
    unlinkGuardian,
    will,
    getWills,
    partialUpdateWill,
    enqueueSnackbar,
    redirectTo
  }) => {

  const [guardiansLoaded, setGuardiansLoaded] = useState(false)

  useEffect(() => {
    getPeople()
    getPartners()
    getChildren()
    getGuardians(() => setGuardiansLoaded(true))
  }, [getPeople, getPartners, getChildren, getGuardians])

  const childBelow18 = () => {
    let below18 = false
    children.forEach(child => {
      if(child.age < 18) below18 = true;
    })
    return below18;
  }

  const linkGuardianHook = (id) => linkGuardian(id)

  const linkGuardianHookReload = (id) => {
    setGuardiansLoaded(false)
    linkGuardian(id, () => setGuardiansLoaded(true))
  }

  const unlinkGuardianHook = (id) => {
    let guardian = guardians.filter(guardian => guardian === id)
    unlinkGuardian(guardian[0], () => setGuardiansLoaded(true))
  }

  const helpTextChildren = "" +
    "<p>Select <b class='font-primary'>No</b> for now if your first child is on the way. This can always be updated in the future.</p>" +
    "<p>If your child has a disability or will need a guardian after they turn 18, please contact us at <a href='mailto:info@truewills.co.uk' >info@truewills.co.uk</a> for specialist advice.</p>"

  const helpTextGuardian = "" +
    "<p>Your children will be taken care of by two guardians if everyone with parental responsibility for them has died before they turn 18.</p>" +
    "<p>This means that you should not choose your spouse as a guardian if they already have parental responsibility for your children.</p>"

  return (
    <>
    <Helmet><title>{section["label"]}</title></Helmet>
    <Grid container spacing={5}>
      <Hidden smDown>
        <Grid item sm={12} xs={12} md={4}>
          <WillSectionStepper activeStepIndex={stepIndex} steps={sectionSteps} valid={will.valid[sectionSlug]}/>
        </Grid>
      </Hidden>
      <Grid item sm={12} xs={12} md={4}>
        <Hidden mdUp>
          <Box mb={3}>
            <WillSectionStepper activeStepIndex={stepIndex} steps={sectionSteps} valid={will.valid[sectionSlug]}/>
          </Box>
        </Hidden>
        <Typography variant="h1" align="center">{section["label"]}</Typography>
        <MobileHelpText text={helpTextChildren} />
        <Formik
          initialValues={{
            children: _.isEmpty(children) ? "no" : "yes",
          }}
          enableReinitialize={true}
          validationSchema={
            yup.object().shape({
              children: yup.string()
                .label('Children')
                .required(),
            })}
          onSubmit={async (values, { setSubmitting }) => {

            let valid = true

            if(values.children === "yes" && _.isEmpty(children)){
              setSubmitting(false)
              enqueueSnackbar({
                message: "Please add at least one child",
                options: {variant: "error"},
              })
              valid = false

            }

            if(values.children === "yes" && childBelow18() && guardians.length !== 2){
              enqueueSnackbar({
                message: "Please select two guardians",
                options: {variant: "error"},
              })
              valid = false
            }

            if(valid) {
              let progress = will.progress
              progress[sectionSlug][sectionName] = true
              await partialUpdateWill(will.id, {'progress': progress})

              if(values.children === "no"){
                await deleteAllChildren()
              }

              getWills()
              redirectTo(nextSection["url"])
            }

          }}
        >

          {({isSubmitting, touched, errors, values}) => {
            return (
              <Form>
                <Box mt={5} mb={1}>
                  <Box mb={1} >
                    <Typography variant="h4">Do you have any children? </Typography>
                  </Box>
                  <SelectField
                    name="children"
                    values={{
                      "yes": "Yes",
                      "no": "No",
                    }}
                    fullWidth
                  />

                </Box>
                {values.children ==='yes'?
                  <>
                    {children.map((child) => (
                          <Box mb={1}>
                            <ChildListItem
                              key={child.id}
                              child={child}
                            />
                          </Box>
                        ))}
                    <AddChildDialog dialogButtonText="Add child" />

                    {childBelow18() &&
                     <>
                     {guardiansLoaded ?
                       <>
                         <Box mt={3} mb={2}>
                            <Typography variant="h4">Guardians </Typography>
                            <MobileHelpText text={helpTextGuardian} />
                         </Box>

                         {people.filter(person => person.id !== partner && person.over18).map((person) => (
                          <Box mb={1}>
                            <PersonListItem
                              key={person.id}
                              personType="guardian"
                              person={person}
                              selectable={true}
                              checked={guardians.some(guardian => guardian === person.id)}
                              onCheckedHook={linkGuardianHook}
                              onUncheckedHook={unlinkGuardianHook}
                            />
                          </Box>
                         ))}

                         <AddPersonDialog
                          personType="guardian"
                          dialogButtonText="Add guardian"
                          linkPersonAction={linkGuardianHookReload}
                        />
                      </>

                      :
                      <Box mt={2} mb={1} display="flex" justifyContent="center">
                        <CircularProgress />
                      </Box>
                     }
                     </>
                    }
                  </>
                  : null}



                <Box mt={3} mb={3}>
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} fullWidth>Save and continue <ChevronRight size={20}/></Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </Grid>
      <Hidden smDown>
        <Grid item sm={12} xs={12} md={4}>
          <Box mt={10}>
            <Box mb={2}>
              <StandardHelpText text={helpTextChildren}/>
            </Box>
            {childBelow18() && <StandardHelpText text={helpTextGuardian}/> }
          </Box>
        </Grid>
      </Hidden>
    </Grid>
  </>
  )
};

const mapStateToProps = ({ account, global, people, wills }) => ({
  user: account.user,
  people: people.people,
  partner: people.partners[0],
  children: people.children,
  guardians: people.guardians,
  formError: global.formError,
  will: wills.wills[0]

});

const mapDispatchToProps = dispatch => ({
  getChildren: () => dispatch(getChildrenAction()),
  deleteAllChildren: (postSuccessHook) => dispatch(deleteAllChildrenAction(postSuccessHook)),
  getPeople: () => dispatch(getPeopleAction()),
  getPartners: () => dispatch(getPartnersAction()),
  getGuardians: (postSuccessHook) => dispatch(getGuardiansAction(postSuccessHook)),
  linkGuardian: (guardianID, postSuccessHook) => dispatch(linkGuardianAction(guardianID, postSuccessHook)),
  unlinkGuardian: (guardianID, postSuccessHook) => dispatch(unlinkGuardianAction(guardianID, postSuccessHook)),
  partialUpdateWill: (willID, formData) => dispatch(partialUpdateWillAction(willID, formData)),
  enqueueSnackbar: (notification) => dispatch(enqueueSnackbarAction(notification, dispatch)),
  getWills: () => dispatch(getWillsAction()),
  redirectTo: (url) => dispatch(push(url))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutYouChildren);
