export const routes = {
    home: '/',
    preRegister: '/bismillah/:qid',
    start: '/bismillah/start',
    register: '/register',
    activation: '/activate/:uid/:token',
    activationResend: '/activate/resend',
    passwordReset: '/password/reset',
    passwordResetConfirm: '/password/reset/confirm/:uid/:token',
    login: '/login',
    account: '/account',
    referred: '/referred',
    summary: '/summary',
    will: '/my-islamic-will',
    willPreview: '/my-islamic-will/preview',
    invite: '/invite',
    aboutYouNames: '/about-yourself/names',
    aboutYouContact: '/about-yourself/contact',
    aboutYouMaritalStatus: '/about-yourself/marital-status',
    aboutYouPartner: '/about-yourself/marital-status',
    aboutYouChildren: '/about-yourself/children',
    aboutYouParents: '/about-yourself/parents',
    aboutYouOtherRelatives: '/about-yourself/other-relatives',
    accountAndProperty: '/accounts-and-property',
    charities: '/charities',
    executorsIntroduction: '/executors/introduction',
    wassiyaCharity: '/wasiyyah/charity',
    wassiyaMessages: '/wasiyyah/messages',
}
