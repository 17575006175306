import React, { useMemo, useEffect, useRef } from "react";
import _ from 'lodash'
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { routes } from "routes";
import { Eye, CheckSquare } from 'react-feather';
import WillDownload from "components/molecules/WillDownload/WillDownload";
import styled from "styled-components";
import { theme } from "theme/mainTheme";
import segmentTrack from "utils/SegmentIO";
import WillSubmit from "components/molecules/WillSubmit/WillSubmit";
import pieChartImg from "images/pie-chart.png";
import Paper from "components/atoms/Paper/Paper";
import PieChart from "components/molecules/DonutChart/DonutChart";
import { getWills } from "redux/actions/wills";


const StyledWillDownload = styled(WillDownload) `
  background-color: ${theme.palette.success.main};
  width: 100%;
  color: white;
  &:hover{
    background-color: ${theme.palette.success.main};
  }
`

const PieChartSection = styled(Box)`
  margin-top: 20px;
  margin-bottom: 20px;
  
  .chart-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
  }
  
  .chart-text {
    flex: 1;
    text-align: left;
    padding-right: 20px;
    max-width: 40%;
  }
  
  .chart-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 60%;
  }
  
  img {
    max-width: 150px;
    display: block;
  }
  
  .chart-legend {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    margin-bottom: 15px;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .legend-color {
    width: 15px;
    height: 15px;
    margin-right: 8px;
    border-radius: 2px;
  }
  
  .asset-value {
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: left;
    font-size: 14px;
    color: ${theme.palette.text.secondary};
  }
`


// Helper function to get colors for chart slices
const getColorForIndex = (index) => {
  const colors = [
    theme.palette.primary.main,
    theme.palette.warning.main,
    theme.palette.info.main,
    theme.palette.success.main
  ];
  return colors[index % colors.length];
};

// Reusable Pie Chart Component
const PieChartComponent = ({ chartData, hasChartData, formattedAssetValue, showHeadsUp = true }) => (
  <Paper>
    <Typography variant="h4" align="center">Inheritance Distribution</Typography>
    <PieChartSection>
      <Box display="flex" alignItems="center">
        <Box className="chart-text" width="40%">
          {!hasChartData ? (
            showHeadsUp && (
              <>
                <Typography variant="h4">Heads up</Typography>
                <p className="font-secondary">
                  Fill in all the required information, to generate a preview of your inheritance distribution
                </p>
              </>
            )
          ) : (
            <Box className="chart-legend">
              {chartData.map((item, index) => (
                <Box key={index} className="legend-item">
                  <Box 
                    className="legend-color" 
                    style={{ backgroundColor: item.color }}
                  />
                  <Typography variant="body2">{item.label} ({Math.round(item.value)}%)</Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
        <Box className="chart-image" width="60%">
          {hasChartData 
            ? <PieChart data={chartData} />
            : <img src={pieChartImg} alt="Inheritance distribution chart" />
          }
        </Box>
      </Box>
      
      {hasChartData && (
        <Typography className="asset-value">
          Your approximate asset value is: {formattedAssetValue}
        </Typography>
      )}
    </PieChartSection>
  </Paper>
);

const YourWill = ({user, will, getWills}) => {
  // Use a ref to track if we've already loaded the data
  const dataLoaded = useRef(false);
  const willIdRef = useRef(null);
  
  // Fetch will data when component mounts or when will ID changes
  useEffect(() => {
    // Check if the will ID has changed
    const willIdChanged = will && will.id && willIdRef.current !== will.id;
    
    // Update the ref with the current will ID
    if (will && will.id) {
      willIdRef.current = will.id;
    }
    
    // Check if we already have shares data
    const hasSharesData = will && will.shares && will.shares.length > 0;
    
    // Only fetch data if we haven't already or if the will ID changed, and the required steps are completed
    if ((!dataLoaded.current || willIdChanged) && !hasSharesData && will && will.progress_summary && 
        will.progress_summary.about_yourself && 
        will.progress_summary.accounts_and_property) {
      getWills();
      dataLoaded.current = true;
    }
  }, [getWills, will]);

  // Generate chart data from shares in the will object
  const chartData = useMemo(() => {
    if (!will) return [];
    
    // Check if steps 1 and 2 are completed
    const stepsCompleted = will.progress_summary && 
                          will.progress_summary.about_yourself && 
                          will.progress_summary.accounts_and_property;
    
    if (stepsCompleted) {
      // Use shares data from the will object if available
      if (will.shares && will.shares.length > 0) {
        return will.shares.map(share => ({
          label: share.label,
          value: share.value,
          color: getColorForIndex(will.shares.indexOf(share))
        }));
      }
    }
    
    return [];
  }, [will]);

  const hasChartData = chartData.length > 0;
  
  // Get the asset value from the will data or use default
  const assetValue = (will && will.asset_value) || 125000; // Default fallback value
  
  const formattedAssetValue = new Intl.NumberFormat('en-GB', { 
    style: 'currency', 
    currency: 'GBP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(assetValue);

  return (
    <>
        {/* Display "You're almost there" message if will is not valid */}
        {!_.isEmpty(will) && !will.valid_summary['all'] && will.status === null &&
          <>
            <Typography variant="h1"> You're almost there!</Typography>
            <p className="font-secondary" >Complete all steps before submitting your Islamic will for expert checking.</p>
            
            {/* Always display pie chart regardless of progress steps */}
            <PieChartComponent chartData={chartData} hasChartData={hasChartData} formattedAssetValue={formattedAssetValue} />
          </>
        }

        {/* Display "Congratulations" message if will is valid */}
        {!_.isEmpty(will) && will.valid_summary['all'] && will.status === null &&
          <>
            <Typography variant="h1">Congratulations! Your Islamic will is now ready for expert checking.</Typography>
            <p className="font-secondary">
              {user.is_active ?
                "Upon payment, our in-house solicitor will check your will."
              : "We need you to confirm your email address before you can submit your will for checking."}
            </p>
            
            <Box mt={3} mb={3}>
              <Typography variant="h4">Total to pay <span style={{'float': 'right'}}>&pound;{will.price / 100}</span></Typography>
              <i className="text-muted"> <small>Includes unlimited changes for a whole year, even after purchase, absolutely free.</small> </i>
            </Box>

            <Button component={RouterLink} to={routes.willPreview} onClick={() => segmentTrack("MIW | Clicked preview and send for checking")} type="submit" variant="contained" color="primary" disableElevation fullWidth startIcon={<CheckSquare size={20}/>}> Preview and send for checking</Button>
            
            {/* Always display pie chart */}
            <Box mt={4}>
              <PieChartComponent chartData={chartData} hasChartData={hasChartData} formattedAssetValue={formattedAssetValue} showHeadsUp={false} />
            </Box>
          </>
        }

        {!_.isEmpty(will) && will.valid_summary['all'] && will.paid && will.status === 'reviewing' &&
          <>
            <Typography variant="h1">Your Islamic will is now being checked by our in-house solicitor.</Typography>
            <p className="font-secondary">You will be notified via email when your will is ready to be downloaded. You can make changes to your Islamic will for free at anytime in the first year.</p>

            <Button component={RouterLink} to={routes.willPreview}  variant="contained" color="secondary" disableElevation fullWidth startIcon={<Eye/>}>Preview your will</Button>
            
            {/* Add PieChart for reviewing state */}
            <Box mt={4}>
              <PieChartComponent chartData={chartData} hasChartData={hasChartData} formattedAssetValue={formattedAssetValue} showHeadsUp={false} />
            </Box>
          </>
        }

        {!_.isEmpty(will) && will.paid && will.status === 'change_requested' &&
          <>
            <Typography variant="h1">You need to make changes to your Islamic will.</Typography>
            <p className="font-secondary">Once you resubmit your will, you will be notified via email when it's ready to be downloaded.</p>

            <Box mt={2} display="flex" justifyContent="space-between">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} lg={6}>
                  <Button component={RouterLink} to={routes.willPreview}  variant="contained" color="secondary" disableElevation fullWidth startIcon={<Eye/>}>Preview your will</Button>
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                  <WillSubmit buttonText="Re-submit your will"/>
                </Grid>
              </Grid>
            </Box>
            
            {/* Add PieChart for change_requested state */}
            <Box mt={4}>
              <PieChartComponent chartData={chartData} hasChartData={hasChartData} formattedAssetValue={formattedAssetValue} showHeadsUp={false} />
            </Box>
          </>
        }

        { !_.isEmpty(will) && will.paid && will.status === 'approved' &&
          <>
            <Typography variant="h1">
              {will.changed && will.valid_summary['all']? "You have made changes to your Islamic will." : "Your Islamic will is ready!" }
            </Typography>
            {will.changed && will.valid_summary['all'] ?
              <>
                <p className="font-secondary">Your updated will is available immediately. You must now print and sign your updated Islamic will in front of two witnesses to make it legally binding.</p>
              </>
              :
              <p className="font-secondary">You must now print and sign your Islamic will in front of two witnesses to make it legally binding.</p>
            }

            <Box mt={2} display="flex" justifyContent="space-between">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} lg={6}>
                  <Button component={RouterLink} to={routes.willPreview}  variant="contained" color="secondary" disableElevation
                        fullWidth startIcon={<Eye/>} align="center">Preview your will</Button>
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                  <StyledWillDownload fullWidth buttonText="Download your will" />
                </Grid>
              </Grid>
            </Box>
            
            {/* Add PieChart for approved state */}
            <Box mt={4}>
              <PieChartComponent chartData={chartData} hasChartData={hasChartData} formattedAssetValue={formattedAssetValue} showHeadsUp={false} />
            </Box>
          </>
        }
        
        {/* Default case when will is empty */}
        {_.isEmpty(will) &&
          <>
            <Typography variant="h1">Start creating your Islamic will</Typography>
            <p className="font-secondary">Begin the process of creating your Islamic will by completing all the required steps.</p>
            
            {/* Display the chart component with placeholder image */}
            <PieChartComponent chartData={[]} hasChartData={false} formattedAssetValue="£0" />
          </>
        }
    </>
  )
}

const mapStateToProps = ({ account, wills }) => ({
  user: account.user,
  will: wills.wills[0]
});

const mapDispatchToProps = {
  getWills,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(YourWill);


