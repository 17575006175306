import React, { useEffect } from 'react';
import _ from 'lodash'
import { Box, Button, Grid, Hidden, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { connect } from 'react-redux';
import { ChevronRight } from 'react-feather';
import {
  deleteUserCharity as deleteUserCharityAction,
  getCharities as getCharitiesAction,
  getCharityShares as getCharitySharesAction,
  submitCharityShares as submitCharitySharesAction
} from "redux/actions/wasiyyah";
import { enqueueSnackbar as enqueueSnackbarAction } from 'redux/actions/snackbar'
import RadioField from "components/atoms/RadioField/RadioField";
import TextField from "components/atoms/TextField/TextField";
import { getWills as getWillsAction, partialUpdateWill as partialUpdateWillAction } from "redux/actions/wills";
import WillSectionStepper from "components/organisms/Stepper/WillSectionStepper";
import { sectionSlug, sectionSteps } from "views/will/Wassiya/Steps";
import SectionResolver from "utils/SectionsResolver";
import MobileHelpText from "components/molecules/HelpText/MobileText";
import StandardHelpText from "components/molecules/HelpText/StandardHelpText";
import { routes } from "routes";
import authAxios from "utils/authAxios";

const stepIndex = 0
const [sectionName, section, nextSection] = SectionResolver(sectionSteps, stepIndex)

const WasiyyahCharity = ({
     user,
     charities,
     getCharities,
     charityShares,
     getCharityShares,
     submitCharityShares,
     deleteUserCharity,
     will,
     getWills,
     partialUpdateWill,
     enqueueSnackbar
  }) => {

  useEffect(() => {
    getCharities()
    getCharityShares()

  }, [getCharities, getCharityShares])

  const addCharityInit = () => {
    if(!will.progress.wassiya.charity) return "yes"
    else return _.isEmpty(charityShares)? "no" : "yes"
  }

  const defaultCharityName = () => {
    if (!_.isEmpty(charityShares) && !_.isEmpty(charities)) {
      const charity = charities.find(c => c.id === charityShares.charity);
      return charity ? charity.name : '';
    }
    return '';
  }

  const defaultCharityNumber = () => {
    if (!_.isEmpty(charityShares) && !_.isEmpty(charities)) {
      const charity = charities.find(c => c.id === charityShares.charity);
      return charity ? charity.charity_number : '';
    }
    return '';
  }

  const defaultCharityAmount = () => {
    if (!_.isEmpty(charityShares) && !_.isEmpty(charities)) {
      const charity = charities.find(c => c.id === charityShares.charity);
      return charity && charity.amount ? charity.amount : '';
    }
    return '';
  }

  const helpText = "" +
    "<p class='font-primary'><b>How much wealth can I leave to a charity?</b></p>" +
    "<p>As a Muslim, you can leave up to 33% of your total wealth to those who aren't entitled to inherit from you.</p>" +
    "<p>Including a charity in your Islamic will is a great opportunity to earn Sadaqah Jariyah, a continuous and ongoing charity after you die.</p>"

  return (
    <>
    <Helmet><title>{section["label"]}</title></Helmet>
    <Grid container direction="row" spacing={5}>
      <Hidden smDown>
        <Grid item sm={12}  xs={12} md={4}>
          <WillSectionStepper activeStepIndex={stepIndex} steps={sectionSteps} valid={will.progress[sectionSlug]}/>
        </Grid>
      </Hidden>
      <Grid item sm={12} md={4}>
        <Hidden mdUp>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={4}>
              <Box mb={3}>
                <WillSectionStepper activeStepIndex={stepIndex} steps={sectionSteps} valid={will.progress[sectionSlug]}/>
              </Box>
            </Grid>
          </Grid>
        </Hidden>
        <Typography variant="h1" align="center">{section["label"]}</Typography>
        <MobileHelpText text={helpText} collapsable={true} />
        <Formik
          initialValues={{
            include_charity: addCharityInit(),
            charity_name: defaultCharityName(),
            charity_number: defaultCharityNumber(),
            charity_amount: defaultCharityAmount(),
          }}
          enableReinitialize={true}
          validationSchema={
            yup.object().shape({
              include_charity: yup.string()
                .label('Charity')
                .required(),
              charity_name: yup.string().when('include_charity', {
                  is: "yes",
                  then: yup.string().label('Charity name').required('Charity name is required')
              }),
              charity_number: yup.number().when('include_charity', {
                  is: "yes",
                  then: yup.number().typeError('Charity number must be a number').label('Charity number').required('Charity number is required')
              }),
              charity_amount: yup.number().when('include_charity', {
                  is: "yes",
                  then: yup.number().typeError('Charity amount must be a number').label('Charity amount').required('Charity amount is required')
              }),
            })}

          onSubmit={async (values, { setSubmitting }) => {

            if(values.include_charity === 'no'){
              deleteUserCharity()
            }else{
              // First, create a new charity
              const charityResponse = await authAxios().post('wasiyyah/charities/', {
                name: values.charity_name,
                charity_number: values.charity_number,
                amount: values.charity_amount
              });
              
              // Then create a charity share with the new charity ID
              await submitCharityShares({
                charity: charityResponse.data.id
              });
            }

            let progress = will.progress
            progress[sectionSlug][sectionName] = true
            await partialUpdateWill(will.id, {'progress': progress}, values.include_charity)

            getWills()
          }}
        >

          {({isSubmitting, setFieldValue, touched, errors, values}) => {
            return (
              <Form>
                <Box mt={5}>
                  <Box mb={1}>
                    <Typography variant="h4">Would you like to leave a share of your wealth to a charity?</Typography>
                  </Box>
                    <RadioField
                      name="include_charity"
                      values={{
                        "yes": "Yes",
                        "no": "No",
                      }}
                      horizontal
                    />

                  { values.include_charity === 'yes' && (
                    <Box mt={2}>
                      <Box mb={1}>
                        <Typography variant="h4">Who would you like to leave it to?</Typography>
                      </Box>

                      <Box mb={2}>
                        <TextField
                          name="charity_name"
                          label="Charity name"
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </Box>
                      
                      <Box mb={2}>
                        <TextField
                          name="charity_number"
                          label="Charity number"
                          variant="outlined"
                          fullWidth
                          required
                          type="number"
                        />
                      </Box>

                      <Box mb={2}>
                        <TextField
                          name="charity_amount"
                          label="Amount you would like to donate?"
                          variant="outlined"
                          fullWidth
                          required
                          type="number"
                          inputProps={{ step: "0.01" }}
                          InputProps={{
                            startAdornment: <Typography style={{ marginRight: 8 }}>£</Typography>
                          }}
                        />
                      </Box>
                    </Box>
                  )}

                </Box>

                <Box mt={3} mb={3}>
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} fullWidth>Save and continue <ChevronRight size={20}/></Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </Grid>
      <Hidden smDown>
        <Grid item sm={12} xs={12} md={4}>
          <Box mt={10}>
            <StandardHelpText text={helpText}/>
          </Box>
        </Grid>
      </Hidden>
    </Grid>
  </>
  )
};

const mapStateToProps = ({ account, global, wasiyyah, wills }) => ({
  user: account.user,
  charities: wasiyyah.charities,
  charityShares: wasiyyah.charity_shares,
  formError: global.formError,
  will: wills.wills[0]
});

const mapDispatchToProps = dispatch => ({
  getCharities: () => dispatch(getCharitiesAction()),
  getCharityShares: () => dispatch(getCharitySharesAction()),
  getWills: () => dispatch(getWillsAction()),
  submitCharityShares: (formData) => dispatch(submitCharitySharesAction(formData)),
  deleteUserCharity: () => dispatch(deleteUserCharityAction()),
  partialUpdateWill: (willID, formData) => {
    const redirectUrl = nextSection["url"]
    return dispatch(partialUpdateWillAction(willID, formData, () => {}, redirectUrl));
  },
  enqueueSnackbar: (notification) => dispatch(enqueueSnackbarAction(notification, dispatch))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WasiyyahCharity);
