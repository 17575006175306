import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { CheckSquare } from 'react-feather';
import { connect } from "react-redux";
import {
  getWills as getWillsAction,
  submitWill as submitWillAction
} from "redux/actions/wills";
import segmentTrack from "utils/SegmentIO";


const WillSubmit = ({user, buttonText, will, getWills, submitWill, enqueueSnackbar }) => {

  const [disabled, setDisabled] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if(!will.valid_summary['all']){
      setDisabled(true)
    }else{
      setDisabled(false)
    }
  }, [will.valid_summary])

  const handleClick = async() => {
    setSubmitting(true)
    await submitWill(() => {
      segmentTrack("MIW | Re-submitted Islamic will")
    })
    await getWills()
  }
  return (
    <Button type="submit" variant="contained" color="primary" onClick={handleClick} disabled={disabled || submitting} disableElevation fullWidth startIcon={submitting? <CircularProgress size={25} /> : <CheckSquare size={20}/>}>{buttonText}</Button>
  )
}

const mapStateToProps = ({ account, wills }) => ({
  user: account.user,
  will: wills.wills[0]
});


const mapDispatchToProps = dispatch => ({
  getWills: () => dispatch(getWillsAction()),
  submitWill: () => dispatch(submitWillAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WillSubmit);


