import * as actions from 'redux/actions/wasiyyah/types'
import update from 'immutability-helper';

const initialState = {
  charities: [],
  charity_shares: {},
  recipient_shares: [],
  message: ""
}

const wasiyyahReducer = (state = initialState, action) => {
  switch (action.type){

    case actions.GET_CHARITIES_START:
      return {...state}
    case actions.GET_CHARITIES_SUCCESS:
      return {...state, charities: action.data}
    case actions.GET_CHARITIES_ERROR:
      return {...state}

    case actions.GET_CHARITY_SHARES_START:
      return {...state}
    case actions.GET_CHARITY_SHARES_SUCCESS:
      return {...state, charity_shares: action.data}
    case actions.GET_CHARITY_SHARES_ERROR:
      return {...state}

    case actions.SUBMIT_CHARITY_SHARES_START:
      return {...state}
    case actions.SUBMIT_CHARITY_SHARES_SUCCESS:
      return {...state, charity_shares: action.data}
    case actions.SUBMIT_CHARITY_SHARES_ERROR:
      return {...state}

    case actions.DELETE_USER_CHARITY_START:
      return {...state}
    case actions.DELETE_USER_CHARITY_SUCCESS:
      return {...state, charity_shares: {}}
    case actions.DELETE_USER_CHARITY_ERROR:
      return {...state}

    case actions.GET_RECIPIENT_SHARES_START:
      return {...state}
    case actions.GET_RECIPIENT_SHARES_SUCCESS:
      return {...state, recipient_shares: action.data}
    case actions.GET_RECIPIENT_SHARES_ERROR:
      return {...state}


    case actions.CREATE_RECIPIENT_SHARES_START:
      return {...state}
    case actions.CREATE_RECIPIENT_SHARES_SUCCESS:
      return {...state, recipient_shares: [...state.recipient_shares, action.data]}
    case actions.CREATE_RECIPIENT_SHARES_ERROR:
      return {...state}

    case actions.PARTIAL_UPDATE_RECIPIENT_SHARES_START:
      return {...state}
    case actions.PARTIAL_UPDATE_RECIPIENT_SHARES_SUCCESS:
      return update(state, {
        recipient_shares: {
          [state.recipient_shares.findIndex(share => share.id === action.shareID)] : {
            $set: action.data }
          }
      })
    case actions.PARTIAL_UPDATE_RECIPIENT_SHARES_ERROR:
      return {...state}

    case actions.DELETE_RECIPIENT_SHARE_START:
      return {...state}
    case actions.DELETE_RECIPIENT_SHARE_SUCCESS:
      return update(state, {
        recipient_shares: {$splice: [[state.recipient_shares.findIndex(share => share.id === action.shareID), 1]]}
      })
    case actions.DELETE_RECIPIENT_SHARE_ERROR:
      return {...state}

    case actions.DELETE_RECIPIENT_SHARES_START:
      return {...state}
    case actions.DELETE_RECIPIENT_SHARES_SUCCESS:
      return {...state, recipient_shares: []}
    case actions.DELETE_RECIPIENT_SHARES_ERROR:
      return {...state}

    case actions.GET_MESSAGE_START:
      return {...state}
    case actions.GET_MESSAGE_SUCCESS:
      return {...state, message: action.data}
    case actions.GET_MESSAGE_ERROR:
      return {...state}

    case actions.SUBMIT_MESSAGE_START:
      return {...state}
    case actions.SUBMIT_MESSAGE_SUCCESS:
      return {...state, message: action.data}
    case actions.SUBMIT_MESSAGE_ERROR:
      return {...state}


    default:
      return state
  }
};

export default wasiyyahReducer

