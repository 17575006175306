import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import authAxios from "utils/authAxios";
import styled from "styled-components";
import { Box, Grid, Typography } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import { Helmet } from "react-helmet";
import SummaryBox from "components/organisms/SummaryBox/SummaryBox";

import { generateWillRevision as generateWillRevisionAction } from "redux/actions/wills";
import { enqueueSnackbar as enqueueSnackbarAction } from "redux/actions/snackbar";
import { connect } from "react-redux";
import { push } from "connected-react-router";

const StyledIframe = styled.iframe`
  position:relative;
  width: 100%;
  height: 700px;
  display: none;
`

const WillPreview = ({ user, will, generateWillRevision, enqueueSnackbar, redirectTo }) => {

  const [draft, setDraft] = useState('')

  useEffect(() => {
    authAxios().get('wills/draft').then((response) => {
      setDraft(response.data)
    })
  }, [])

  const iframeLoad = (frame) => {
    let target = frame.target
    setTimeout(function() {
      target.style.display = "block"
    }, 300)
  }

  return (
    <>
      <Helmet>
        <title>Islamic will preview</title>
        <meta name="segment-event-name" content="WPP | Viewed page" />
      </Helmet>

      <Grid container spacing={5}>
        <Box clone order={{ xs: 2, md: 1 }}>
          <Grid item md={7} sm={10} xs={12} >
            <Box mb={2}>
              <Typography variant="h1">Islamic will preview</Typography>
            </Box>
            {!_.isEmpty(draft) ?
              <StyledIframe srcDoc={draft} height="700px" width="100%"  frameBorder="0" onLoad={(e) => iframeLoad(e)}></StyledIframe>
              :
              <Skeleton variant="rect" height={600} />
            }
          </Grid>
        </Box>

        <Box clone order={{ xs: 1, md: 2 }}>
          <Grid item md={5} sm={10} xs={12} >
            <Box mb={5}>
              <Typography variant="h1">{user.preferred_name}'s Islamic will</Typography>
            </Box>
              <SummaryBox />
          </Grid>
        </Box>
      </Grid>


    </>
  )
};

const mapStateToProps = ({ account, global, wills }) => ({
  user: account.user,
  will: wills.wills[0]
});

const mapDispatchToProps = dispatch => ({
  generateWillRevision: (postSuccessHook) => dispatch(generateWillRevisionAction(postSuccessHook)),
  redirectTo: (url) => dispatch(push(url)),
  enqueueSnackbar: (notification) => dispatch(enqueueSnackbarAction(notification, dispatch))
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WillPreview);
