import React, { useEffect, useRef } from "react";
import { useParams } from 'react-router-dom'
import { Helmet } from "react-helmet";
import * as yup from 'yup'
import { connect } from 'react-redux';
import { passwordResetConfirm as passwordResetConfirmAction } from 'redux/actions/auth/passwordReset';
import { Field, Form, Formik } from 'formik';
import { Button, Grid, Typography } from '@material-ui/core';
import TextField from "components/atoms/TextField/TextField";


const PasswordResetConfirm = ({passwordResetConfirm, formError}) => {

  const formikRef = useRef(null);
  const { uid, token } = useParams();

  useEffect(() => formikRef.current.setErrors(formError) )

  return (<>
    <Helmet>
      <title>Password Reset Confirm</title>
    </Helmet>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item md={4} sm={8}>
          <Typography variant="h1" align="center">Reset Your Password</Typography>
          <br/>
          <Formik
            innerRef={formikRef}
            initialValues={{
              new_password: '',
              new_passwordConfirm: ''
            }}
            validationSchema={
              yup.object().shape({
                new_password: yup.string()
                  .label('Password')
                  .required(),
                new_passwordConfirm: yup.string()
                  .label('Password Confirmation')
                  .oneOf([yup.ref('new_password'), null], "Password needs to match")
                  .required()
              })}

            onSubmit={async (values) => {
              await passwordResetConfirm(uid, token, values.new_password)
            }}
          >
            {({isSubmitting, touched, errors}) => {
              return (
                <Form>
                  <Field type="hidden" id="uid" name="uid"/>
                  <div>
                    <TextField name="new_password" type="password" label="Password" fullWidth/>
                  </div>
                  <div>
                    <TextField name="new_passwordConfirm" type="password" label="Password Confirmation" fullWidth/>
                  </div>
                  <div>
                    <br/>
                    <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} fullWidth> Reset password </Button>
                  </div>
                </Form>
              )
            }}
          </Formik>
      </Grid>
    </Grid>

  </>

)};


const mapStateToProps = ({ global }) => ({
  formError: global.formError,
});

const mapDispatchToProps = dispatch => ({
  passwordResetConfirm: (email, uid, token) => dispatch(passwordResetConfirmAction(email, uid, token)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PasswordResetConfirm);

