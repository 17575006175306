import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import styled from 'styled-components';
import { theme } from 'theme/mainTheme';

const ChartContainer = styled.div`
  height: 250px;
  width: 100%;
  position: relative;
  
  svg {
    height: 100%;
    width: 100%;
  }
  
  .slice {
    transition: opacity 0.3s;
  }
  
  .slice:hover {
    opacity: 0.8;
  }
`;

const PieChart = ({ data }) => {
  const svgRef = useRef(null);

  useEffect(() => {
    if (!data || !data.length) return;

    // Clear any existing chart
    d3.select(svgRef.current).selectAll('*').remove();

    // Set up dimensions
    const width = 220;
    const height = 220;
    const margin = 0;
    
    // Radius calculation
    const radius = Math.min(width, height) / 2 - margin;
    
    // Create SVG
    const svg = d3.select(svgRef.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);
    
    // Set up pie generator
    const pie = d3.pie()
      .value(d => d.value)
      .sort(null);
    
    // Set up arc generator for pie (no inner radius)
    const arc = d3.arc()
      .innerRadius(0) // This makes it a pie chart (innerRadius = 0)
      .outerRadius(radius);
    
    // Create color scale
    const color = d => d.data.color || theme.palette.grey[400];
    
    // Draw chart
    svg.selectAll('path')
      .data(pie(data))
      .join('path')
      .attr('d', arc)
      .attr('fill', color)
      .attr('stroke', 'white')
      .attr('stroke-width', 1)
      .attr('class', 'slice')
      .on('mouseover', function() {
        d3.select(this).transition()
          .duration(200)
          .attr('opacity', 0.8);
      })
      .on('mouseout', function() {
        d3.select(this).transition()
          .duration(200)
          .attr('opacity', 1);
      });
    
    // Add percentage labels if needed
    svg.selectAll('text')
      .data(pie(data))
      .join('text')
      .text(d => `${Math.round(d.data.value)}%`)
      .attr('transform', d => `translate(${arc.centroid(d)})`)
      .style('text-anchor', 'middle')
      .style('font-size', '12px')
      .style('fill', 'white')
      .style('font-weight', 'bold')
      .style('display', d => (d.endAngle - d.startAngle > 0.25 ? 'block' : 'none')); // Only show label if slice is big enough
    
  }, [data]);

  return (
    <ChartContainer>
      <div ref={svgRef}></div>
    </ChartContainer>
  );
};

export default PieChart; 