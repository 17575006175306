import React, { useRef } from 'react'
import _ from "lodash";
import { useEffect } from "react";
import { connect } from 'react-redux';
import { getAccount as getAccountAction } from "redux/actions/account";
import { setContentLoading as setContentLoadingAction, unsetContentLoading as unsetContentLoadingAction } from "redux/actions/root";
import isLogin from "./isLogin";
import { getWills as getWillsAction } from "redux/actions/wills";


const AppProvider = ({children, user, getAccount, wills, getWills, metaTags, getMetaTags, setContentLoading, unsetContentLoading}) => {
  // Use a ref to track if initial data has been loaded
  const initialDataLoaded = useRef(false);

  // Only fetch user and wills data on initial mount or when login state changes
  // This prevents multiple redundant API calls
  useEffect(() => {
    // Only fetch if logged in and data is empty
    if(isLogin() && (_.isEmpty(user) || _.isEmpty(wills)) && !initialDataLoaded.current){
      setContentLoading();
      getAccount();
      getWills();
      initialDataLoaded.current = true;
    } else {
      unsetContentLoading();
    }
  }, [user, wills, getAccount, getWills, setContentLoading, unsetContentLoading]); // Include all dependencies

  return (<>{children}</>)
}

const mapStateToProps = ({ account, wills, seo }) => ({
  user: account.user,
  wills: wills.wills,
});


const mapDispatchToProps = dispatch => ({
  getAccount: () => dispatch(getAccountAction()),
  getWills: () => dispatch(getWillsAction()),
  setContentLoading: () => dispatch(setContentLoadingAction()),
  unsetContentLoading: () => dispatch(unsetContentLoadingAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppProvider);
