import { createStore, applyMiddleware, compose } from "redux";
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import rootReducer from 'redux/reducers'
import thunk from 'redux-thunk';
import callAPIMiddleware from "utils/callAPIMiddleware";
import segmentTrack from "utils/SegmentIO";

export const history = createBrowserHistory()

let prevPath = null;
history.listen((location, action) => {
  if (location.pathname !== prevPath) {
    prevPath = location.pathname;
    setTimeout(() => {
      window.analytics.page()
      let eventName = document.querySelector('meta[name="segment-event-name"]')
      if(eventName){
        segmentTrack(eventName.content );
      }
    }, 1000);
  }
});

// Configure Redux DevTools
const composeEnhancers = (process.env.REACT_APP_HOST_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(
  rootReducer(history),
  composeEnhancers(
    applyMiddleware(thunk, callAPIMiddleware, routerMiddleware(history))
  )
);

export default store;
