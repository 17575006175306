import React, { useEffect } from 'react';
import _ from 'lodash'
import { Box, Grid, Hidden, Typography } from "@material-ui/core";
import SectionBox from "components/organisms/SectionBox/SectionBox";
import ProgressBar from "components/molecules/ProgressBar/ProgressBar";
import { routes } from "routes";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { getWills as getWillsAction } from "redux/actions/wills";
import YourWill from "components/molecules/YourWill/YourWill";
import InviteBox from "components/molecules/InviteBox/InviteBox";

const Will = ({ user, will, getWills }) => {

  // Only fetch wills data if it's not already available
  useEffect(() => {
    if (_.isEmpty(will)) {
      getWills()
    }
  }, [will, getWills])

  return (
    <>
      <Helmet>
        <title>Your will</title>
        <meta name="segment-event-name" content="MIW | Viewed page" />
      </Helmet>
      <Grid container justify="center" spacing={5} >
         {will &&
            <>
              <Box clone order={{ xs: 2, md: 1 }}>
               <Grid item md={6} sm={10} xs={12} >
                <Box  mb={2}>
                  <SectionBox
                    legend="Step 1 (3-4 minutes)"
                    title="About you"
                    description="The important details."
                    startPath={routes.aboutYouNames}
                    valid = {will.valid_summary['about_yourself']}
                    completed = {will.progress_summary['about_yourself']}
                    active={!will.progress_summary['about_yourself']}
                    status={will.status}
                  />
                </Box>
                <Box mb={2}>
                  <SectionBox
                    legend="Step 2 (2-3 minutes)"
                    title="Accounts and property"
                    description="List out where your money is kept."
                    startPath={routes.accountAndProperty}
                    valid={will.valid_summary['accounts_and_property']}
                    completed={will.progress_summary['accounts_and_property']}
                    active={will.progress_summary['about_yourself'] && !will.progress_summary['accounts_and_property']}
                    disabled={!will.progress_summary['about_yourself']}
                    status={will.status}
                  />
                </Box>
                <Box mb={2}>
                  <SectionBox
                    legend="Step 3 (3-4 minutes)"
                    title="Executors and trustees"
                    startPath={routes.executorsIntroduction}
                    description="Choose who's responsible to carry out the instructions in your Islamic will."
                    valid={will.valid_summary['executors']}
                    completed={will.progress_summary['executors']}
                    active={will.progress_summary['accounts_and_property'] && !will.progress_summary['executors']}
                    disabled={!will.progress_summary['accounts_and_property']}
                    status={will.status}
                  />
                </Box>
                <Box mb={2}>
                  <SectionBox
                    legend="OPTIONAL (2-4 minutes)"
                    title="Wasiyyah"
                    startPath={routes.wassiyaCharity}
                    description="Leave a share of your wealth to a charity and to those who aren't entitled to inherit from you."
                    valid={will.valid_summary['wassiya'] && will.progress_summary['wassiya']}
                    completed={will.progress_summary['wassiya']}
                    active={false}
                    disabled={!will.progress_summary['executors']}
                    status={will.status}
                  />
                </Box>
                <Hidden mdUp>
                  <Box mt={5}>
                    <InviteBox/>
                  </Box>
                </Hidden>
              </Grid>

              </Box>

              <Box clone order={{ xs: 1, md: 2 }}>
               <Grid item md={5} sm={10} xs={12}>
                <Box mb={2}>
                  <Box mb={0.5}>
                    <Typography variant="p"><b>Your progress: {will.progress_percent}% complete</b></Typography>
                  </Box>
                  <ProgressBar variant="solid" value={will.progress_percent}/>
                </Box>
                <Box >
                  <YourWill/>
                </Box>
                <Hidden smDown>
                  <Box mt={5}>
                    <InviteBox/>
                  </Box>
                </Hidden>
              </Grid>
              </Box>
            </>
          }
      </Grid>
    </>
  )
};

const mapStateToProps = ({ account, wills }) => ({
  user: account.user,
  will: wills.wills[0]
});

const mapDispatchToProps = dispatch => ({
  getWills: () => dispatch(getWillsAction()),
})


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Will);


