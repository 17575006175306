import React from "react";
import styled from "styled-components";
import { theme } from "theme/mainTheme";
import { Alert, AlertTitle } from '@material-ui/lab';

const StyledAlert = styled(Alert)`
  border: 1px solid #485156;
  background-color: #f3f3f3;
  padding: 25px;
  
  .MuiAlert-message, .MuiAlert-icon{
    color: ${theme.palette.text.primary};
  }
  .text{
    margin-left: -30px;
    font-size: 1.6rem;
  }
  
  a{
    color: ${theme.palette.link.main}
  }
`

const StandardHelpText = ({text}) => {

  return (
     <StyledAlert severity="info">
       <AlertTitle>Info</AlertTitle>
        <p className='text font-secondary' dangerouslySetInnerHTML={{__html: text}} />
     </StyledAlert>
  )
}

export default StandardHelpText

