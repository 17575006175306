import React from 'react';
import { Field, useField } from "formik";
import { Checkbox as MUICheckbox, FormControl, FormControlLabel, FormGroup, FormHelperText } from '@material-ui/core'


const CheckboxField = ({ name, helpText, values, ...props }) => {
  const [, meta] = useField(name);
  return (
     <FormControl component="fieldset" >
      <FormGroup>
        {Object.entries(values).map(([value, label]) => (
              <FormControlLabel
                name={name}
                control={<Field as={MUICheckbox} type="checkbox" name={name}  color="primary" value={value} />}
                label={label}
              />
            ))
        }
       </FormGroup>
       <FormHelperText>{meta.touched && Boolean(meta.error)? meta.error : helpText}</FormHelperText>
     </FormControl>
  )
}

export default CheckboxField

