import React from 'react';
import styled from 'styled-components';
import _ from 'lodash'
import { useField } from "formik";
import { TextField as MUITextField} from '@material-ui/core'
import { theme } from 'theme/mainTheme'


const StyledTextField = styled(MUITextField)`
  
  margin-bottom: 20px;
    
  input {
    background-color: white;
    border-radius: 6px;
  }
  
  .MuiInputBase-root {
    background-color: white;
    border-radius: 6px;
  }
    
  .MuiInputLabel-root {
    color: ${theme.palette.text.muted};
  }
    
  fieldset {
    border-width: 2px;
  }
    
  & .MuiInputBase-root {
    border-radius: 6px;
    border-width: 2px;
 }
 
  & .MuiFormHelperText-root {
    white-space: pre-line;
 }
  
 .MuiSvgIcon-root{
    fill: ${theme.palette.primary.main};
 }

`


const TextField = ({ name, helpText, ...props }) => {
  const [field, meta] = useField(name);

  const parsedError = () => (_.isArray(meta.error) ? meta.error.join('\n') : meta.error)

  return (
    <StyledTextField
        id={field.name}
        name={field.name}
        helperText={meta.touched && Boolean(meta.error)? parsedError() : helpText}
        error={meta.touched && Boolean(meta.error)}
        value={field.value}
        onChange={field.onChange}
        variant="outlined"
        {...props}
    />
  )
}

export default TextField

