import React from 'react';
import _ from 'lodash'
import { Box, Grid, Hidden, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import SummaryBox from "components/organisms/SummaryBox/SummaryBox";
import ProgressBar from "components/molecules/ProgressBar/ProgressBar";
import { Helmet } from "react-helmet";
import ContactBox from "components/molecules/ContactBox/ContactBox";

const Summary = ({user, will }) => {

  return (
    <>
      <Helmet>
        <title>Summary</title>
        <meta name="segment-event-name" content="Summary | Viewed page" />
      </Helmet>
      <Grid container spacing={5}  justify="center">
        <Grid item md={6} >
          <Box mb={-4}>
            <Typography variant="h1">As-salamu alaikum, {user.preferred_name}!</Typography>
            <p className="font-secondary">
              {!_.isEmpty(will) && will.status === null && "Let's sort out your Islamic will."}
              {!_.isEmpty(will) && will.status === 'reviewing' && "We're checking your Islamic will."}
              {!_.isEmpty(will) && will.status === 'approved' && !will.changed && "Your Islamic will has been approved."}
              {!_.isEmpty(will) && will.status === 'approved' && will.changed && "Changes to your Islamic will have been approved."}
              {!_.isEmpty(will) && will.status === 'change_requested' && "Let's sort out your Islamic will."}
            </p>
            <Hidden smDown>
              <ContactBox/>
            </Hidden>
          </Box>
        </Grid>
        <Grid item md={5}>
          {will &&
            <>
              <Box mb={0.5}>
                <Typography variant="body1"><b>Your progress: { will.progress_percent }% complete</b></Typography>
              </Box>
              <ProgressBar variant="solid" value={will.progress_percent } />
            </>
          }
          <Box mt={4}>
            <SummaryBox />
            <Hidden mdUp>
              <Box mt={4}>
                <ContactBox />
              </Box>
            </Hidden>
          </Box>
        </Grid>
      </Grid>
    </>
  )
};

const mapStateToProps = ({ account, wills }) => ({
  user: account.user,
  will: wills.wills[0]
});


export default connect(
  mapStateToProps,
)(Summary);

