import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, Hidden, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Form, Formik } from "formik";
import { connect } from 'react-redux';
import { ChevronRight } from 'react-feather';
import { routes } from "routes";
import AddPersonDialog from "components/molecules/AddPersonDialog/AddPersonDialog";
import {
  getChildren as getChildrenAction,
  getExecutors as getExecutorsAction,
  getPeople as getPeopleAction,
  linkExecutor as linkExecutorAction,
  unlinkExecutor as unlinkExecutorAction
} from "redux/actions/people";
import { enqueueSnackbar as enqueueSnackbarAction } from 'redux/actions/snackbar'
import PersonListItem from "components/molecules/PersonListItem/PersonListItem";
import ChildListItem from "components/molecules/ChildListItem/ChildListItem";
import { partialUpdateAccount as partialUpdateAccountAction } from "redux/actions/account";
import { getWills as getWillsAction, partialUpdateWill as partialUpdateWillAction } from "redux/actions/wills";
import SectionResolver from "utils/SectionsResolver";
import { sectionSlug, sectionSteps } from "views/will/Executors/Steps";
import WillSectionStepper from "components/organisms/Stepper/WillSectionStepper";
import MobileHelpText from "components/molecules/HelpText/MobileText";
import StandardHelpText from "components/molecules/HelpText/StandardHelpText";

const stepIndex = 0
const [sectionName, section] = SectionResolver(sectionSteps, stepIndex)

const ExecutorsIntroduction = ({ user, people, getPeople, executors, getExecutors, children, getChildren, will, getWills, linkExecutor, unlinkExecutor, enqueueSnackbar, partialUpdateAccount, partialUpdateWill }) => {

  const [executorsLoaded, setExecutorsLoaded] = useState(false)

  useEffect(() => {
    getPeople()
    getChildren()
    getExecutors(() => setExecutorsLoaded(true))
  }, [getPeople, getChildren, getExecutors])

  const linkPersonExecutorHook = (id) => linkExecutor(id)
  const linkPersonExecutorHookReload = (id) => {
    setExecutorsLoaded(false)
    linkExecutor(id, '', () => setExecutorsLoaded(true))
  }
  const unlinkPersonExecutorHook = (id) => {
    let executor = executors.filter(executor => executor.person === id)
    unlinkExecutor(executor[0].id)
  }
  const linkChildExecutorHook = (id) => linkExecutor('', id)
  const unlinkChildExecutorHook = (id) => {
    let executor = executors.filter(executor => executor.child === id)
    unlinkExecutor(executor[0].id)
  }

  const helpText = "" +
    "<p class='font-primary'><b>What are executors and trustees?</b></p>" +
    "<p>Executors and trustees carry out the instructions in your Islamic will after you die.</p>" +
    "<p>Executors carry out funeral wishes, apply for probate and value your estate. Trustees manage the trusts which are placed in your will i.e. they look after your wealth in line with your wishes but at their discretion.</p>" +
    "<p class='font-primary'><b>Who do I choose to be my executors and trustees?</b></p>" +
    "<p>To keep things simple, we allow you to choose the same people as your executors and trustees. You can choose family, friends, or a mix of both.</p>"+
    "<p>Please select between 2 and 4 people and ensure that they're over 18, are trustworthy and can comfortably manage finances and paperwork.</p>"

  return (
    <>
    <Helmet>
      <title>{section["label"]}</title>
      <meta name="segment-event-name" content="MIW | Viewed executors page" />
    </Helmet>
    <Grid container direction="row" spacing={5} >
      <Hidden smDown>
        <Grid item sm={12} xs={12} md={4}>
          <WillSectionStepper activeStepIndex={stepIndex} steps={sectionSteps} valid={will.valid[sectionSlug]}/>
        </Grid>
      </Hidden>
      <Grid item sm={12} xs={12} md={4}>
        <Hidden mdUp>
          <Grid container direction="row" justify="center" alignItems="center">
              <Box mb={3}>
                <WillSectionStepper activeStepIndex={stepIndex} steps={sectionSteps} valid={will.valid[sectionSlug]}/>
              </Box>
          </Grid>
        </Hidden>
        <Typography variant="h1" align="center">{section["label"]}</Typography>
        <MobileHelpText text={helpText} />
        <Formik
          initialValues={{ }}
          enableReinitialize={true}
          onSubmit={async (values, { setSubmitting }) => {
           if(executors.length < 2 || executors.length > 4){
                setSubmitting(false)
                enqueueSnackbar({
                  message: "Please select between 2 and 4 executors and trustees",
                  options: {variant: "error"},
                })
                return false
            }
            await partialUpdateAccount(values)
            await partialUpdateAccount(values)

            let progress = will.progress
            progress[sectionSlug][sectionName] = true
            await partialUpdateWill(will.id, {'progress': progress})

            getWills()
          }}
        >

          {({isSubmitting, touched, errors, values}) => {
            return (
              <Form>
                <Box mt={3} mb={1}>
                  <Typography variant="h4" >Who would you like to choose as your executors and trustees?</Typography>
                </Box>
                { executorsLoaded ?
                  <>
                    {people.filter((person) => person.over18).map((person) => (
                      <Box mb={1}>
                        <PersonListItem
                          key={person.id}
                          person={person}
                          personType="executor_and_trustee"
                          selectable={true}
                          checked={executors.some(executor => executor.person === person.id)}
                          onCheckedHook={linkPersonExecutorHook}
                          onUncheckedHook={unlinkPersonExecutorHook}
                          dialogActionButtonRenderPersonType={false}
                        />
                      </Box>
                    ))}

                  </>
                  :
                  <Box mb={1} display="flex" justifyContent="center">
                    <CircularProgress />
                  </Box>
                }
                  <AddPersonDialog
                    personType="executor_and_trustee"
                    dialogButtonText="Add"
                    linkPersonAction={linkPersonExecutorHookReload}
                    actionButtonRenderPersonType={false}
                  />

                { children.some(child => child.age >= 18) ?
                  <Box mt={2} mb={2}>
                    <Typography variant="h4" align="center">You can also select your children over 18</Typography>
                  </Box>

                : null}

                { executorsLoaded && children.filter(child => child.age >= 18).map((child) => (
                  <Box mb={1}>
                    <ChildListItem
                      key={child.id}
                      child={child}
                      selectable={true}
                      checked={executors.some(executor => executor.child === child.id)}
                      onCheckedHook={linkChildExecutorHook}
                      onUncheckedHook={unlinkChildExecutorHook}
                      dialogActionButtonRenderPersonType={false}
                    />
                  </Box>
                ))}

                <Box mt={3} mb={3}>
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} fullWidth>Save and continue <ChevronRight size={20}/></Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </Grid>
      <Hidden smDown>
        <Grid item sm={12} xs={12} md={4}>
          <Box mt={10}>
            <StandardHelpText text={helpText}/>
          </Box>
        </Grid>
      </Hidden>
    </Grid>
  </>
  )
};

const mapStateToProps = ({ account, global, people, wills }) => ({
  user: account.user,
  people: people.people,
  executors: people.executors,
  children: people.children,
  will: wills.wills[0],
  formError: global.formError,
});

const mapDispatchToProps = dispatch => ({
  getPeople: () => dispatch(getPeopleAction()),
  getChildren: () => dispatch(getChildrenAction()),
  getExecutors: (postSuccessHook) => dispatch(getExecutorsAction(postSuccessHook)),
  linkExecutor: (person_id, child_id, postSuccessHook) => dispatch(linkExecutorAction(person_id, child_id, postSuccessHook)),
  unlinkExecutor: (executorID, postSuccessHook) => dispatch(unlinkExecutorAction(executorID, postSuccessHook)),
  enqueueSnackbar: (notification) => dispatch(enqueueSnackbarAction(notification, dispatch)),
  partialUpdateAccount: (formData) => dispatch(partialUpdateAccountAction(formData, routes.will)),
  getWills: () => dispatch(getWillsAction()),
  partialUpdateWill: (willID, formData) => dispatch(partialUpdateWillAction(willID, formData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExecutorsIntroduction);
