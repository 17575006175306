import React , { useRef } from 'react';
import { Box, Button, Grid, Typography, TextField as TextFieldMUI } from "@material-ui/core";
import Paper from "components/atoms/Paper/Paper";
import TextField from "components/atoms/TextField/TextField";
import { Form, Formik } from "formik";
import styled from "styled-components";
import { Send, Copy } from 'react-feather'
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { enqueueSnackbar as enqueueSnackbarAction } from "redux/actions/snackbar";
import { invite as inviteAction } from "redux/actions/invite";
import * as yup from "yup";


const StyledButton = styled(Button)`
  margin-left: 10px;
  width: 30%;
  height: 55px;
`

const StyledTextField = styled(TextField)`
  margin-bottom: 0;
`


const Invite = ({user, enqueueSnackbar, invite}) => {
  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand('copy');
    enqueueSnackbar({
      message: "Your reference link has been copied to clipboard.",
        options: {
          variant: "success",
        },
    })
  }

  return (
    <>
      <Helmet>
        <title>Invite your friends and family</title>
        <meta name="segment-event-name" content="Invite | Viewed page" />
      </Helmet>
       <Grid container direction="row" justify="center" alignItems="center" >
       <Grid item md={5} sm={8} >
          <Box textAlign="center">
          </Box>
          <Box mt={1} mb={1}>
            <Typography variant="h1" align="center">Invite your friends and family</Typography>
          </Box>
          <Box textAlign="center">
            <p className="font-secondary">Earn the reward of inviting others to create an Islamic will.</p>
          </Box>

          <Box mt={3}>
            <Paper>
              <Box mt={1}>
                <Box mb={1}>
                  <Typography variant="h4">Share your personal link</Typography>
                </Box>

                    <Box display="flex">
                      <TextFieldMUI
                        type="input"
                        inputRef={textAreaRef}
                        value={user.ref_link}
                        variant="outlined"
                        readOnly
                        fullWidth
                      />
                      <StyledButton variant="contained" color="primary" onClick={() => copyToClipboard()} disableElevation><Copy size={20}
                        fontSize="small" />&nbsp;Copy</StyledButton>
                    </Box>
              </Box>

              <Box mb={2} mt={2}>
                <Box mb={1}>
                  <Typography variant="h4">Invite via email</Typography>
                </Box>
                <Formik
                  initialValues={{
                    email_address: ''
                  }}
                  enableReinitialize={true}
                  validationSchema={
                    yup.object().shape({
                    email_address: yup.string()
                      .label('Email')
                      .required()
                      .email(),
                  })}
                  onSubmit={async (values, { resetForm }) => {
                    await invite({'email': values.email_address}, () => {
                      resetForm();
                     })
                  }}
                >
                  {({isSubmitting, touched, errors}) => {
                  return (
                  <Form>
                    <Box display="flex" alignItems="flex-start">
                      <StyledTextField name="email_address" label="Email" fullWidth/>
                      <StyledButton type="submit" variant="contained" color="primary" disableElevation disabled={isSubmitting}><Send size={20}
                        fontSize="small"/>&nbsp;Send</StyledButton>
                    </Box>
                  </Form>
                       )
                  }}
                </Formik>
              </Box>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </>
  )
};

const mapStateToProps = ({ account, router }) => ({
  user: account.user,
  router
});

const mapDispatchToProps = dispatch => ({
  invite: (formData, postSuccessHook) => dispatch(inviteAction(formData, postSuccessHook)),
  enqueueSnackbar: (notification) => dispatch(enqueueSnackbarAction(notification, dispatch))
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Invite);



