import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Divider, IconButton, Tooltip } from '@material-ui/core'
import { Add, Edit } from "@material-ui/icons";
import { Box, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import * as yup from "yup";
import TextField from "components/atoms/TextField/TextField";
import { connect } from "react-redux";
import { createAsset as createAssetAction, updateAsset as updateAssetAction } from "redux/actions/assets";
import SelectField from "components/atoms/SelectField/SelectField";
import RadioField from "components/atoms/RadioField/RadioField";
import { enqueueSnackbar as enqueueSnackbarAction } from "redux/actions/snackbar";


const  AddAssetDialog = ({ dialogButtonText, iconButton=false, asset=null, createAsset, partialUpdateAsset, user, userAssets, enqueueSnackbar })  => {
  const [open, setOpen] = useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const postSuccessHook = () => {
      handleClose();
  }

  const nameLabel = {
    'bank_account': 'Bank name',
    'business': 'Business name'
  }

  const initialAddress = () => {
    if(asset) return [asset.address_line_1, asset.address_line_2, asset.city, asset.post_code]
    if(userAssets.some(asset => asset.asset === 'property')) return ['', '', '', '']
    return [user.address_line_1, user.address_line_2, user.city, user.post_code]
  }

  return (
    <div>
      {iconButton ?
        <Tooltip title={dialogButtonText}>
         <IconButton onClick={handleClickOpen} component="span">
              <Edit />
            </IconButton>
        </Tooltip>
        :
        <Button variant="contained" color="secondary"  onClick={handleClickOpen} fullWidth> {dialogButtonText} <Add/></Button>
      }
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth = {'sm'}>
        <DialogTitle id="form-dialog-title"><Typography variant="h2">{asset? "Edit" : "Add"} asset</Typography></DialogTitle>

          <Formik
            initialValues={{
              asset: asset? asset.asset : '',
              name: asset? asset.name : '',
              provider: asset? asset.provider : '',
              gold: asset? asset.gold : '',
              silver: asset? asset.silver : '',
              address_line_1: initialAddress()[0],
              address_line_2: initialAddress()[1],
              city: initialAddress()[2],
              post_code: initialAddress()[3],
              mortgage: asset? asset.mortgage : '',
              ownership: asset? asset.ownership : '',
              value: asset? asset.value : '',
            }}
            validationSchema={
              yup.object().shape({
                asset: yup.string().label('Asset').required(),
                name: yup.string().when('asset', {
                  is: (asset) => asset === 'bank_account' || asset === 'business',
                  then: yup.string().label().required("This field is required")
                }),
                provider: yup.string().when('asset', {
                  is: (asset) => asset === 'pension' || asset === 'life_insurance' || asset === 'stocks_and_shares',
                  then: yup.string().label('Provider').required()
                }),
                address_line_1: yup.string().when('asset', {
                  is: (asset) => asset === 'property',
                  then: yup.string().label('Address').required()
                }),
                city: yup.string().when('asset', {
                  is: (asset) => asset === 'property',
                  then: yup.string().label('City').required()
                }),
                post_code: yup.string().when('asset', {
                  is: (asset) => asset === 'property',
                  then: yup.string().label('Post Code').required()
                }),
                mortgage: yup.string().when('asset', {
                  is: (asset) => asset === 'property',
                  then: yup.string().label('Mortgage').required()
                }),
                ownership: yup.string().when('asset', {
                  is: (asset) => asset === 'property',
                  then: yup.string().label('Ownership').required()
                }),
                value: yup.number()
                  .transform((value) => (isNaN(value) ? undefined : value))
                  .min(1, 'Value must be greater than 0')
                  .required('Approximate value is required'),
              })}

            onSubmit={async(values, {setSubmitting}) => {
              if(values.asset === 'gold_and_silver'){
                if((values.gold === "" || values.gold === 0)  && (values.silver === "" || values.silver === 0)){
                  setSubmitting(false)
                  enqueueSnackbar({
                    message: "Please provide Gold or Silver grams",
                    options: {variant: "error"},
                  })
                  return false
                }
              }

              if(asset) await partialUpdateAsset(asset.id, values, postSuccessHook)
              else await createAsset(values, postSuccessHook)
            }}
          >
            {({isSubmitting, touched, errors, values}) => {
              return (
                <DialogContent>
                <Form>
                  <SelectField
                    size="small"
                    name="asset"
                    label="Asset"
                    values={{
                      "bank_account": "Bank account",
                      "property": "Property",
                      "pension": "Pension",
                      "stocks_and_shares": "Stocks and shares",
                      "business": "Business",
                      "gold_and_silver": "Gold and silver",
                      "life_insurance": "Life insurance"
                    }}
                    fullWidth
                  />

                  <Box mt={2} mb={2}>
                    <Divider/>
                  </Box>

                  {(values.asset === 'bank_account' || values.asset === 'business') &&  (
                    <>
                      <TextField size="small" name="name" label={nameLabel[values.asset]} fullWidth/>
                      <Box mt={2}>
                        <TextField 
                          size="small" 
                          type="number" 
                          name="value" 
                          label="What is the approximate value of this asset?" 
                          fullWidth
                          InputProps={{
                            startAdornment: <span>£</span>,
                          }}
                        />
                      </Box>
                    </>
                  )}

                  {(values.asset === 'pension' || values.asset === 'life_insurance' || values.asset === 'stocks_and_shares') &&  (
                    <>
                      <TextField size="small" name="provider" label="Provider" fullWidth/>
                      <Box mt={2}>
                        <TextField 
                          size="small" 
                          type="number" 
                          name="value" 
                          label="What is the approximate value of this asset?" 
                          fullWidth
                          InputProps={{
                            startAdornment: <span>£</span>,
                          }}
                        />
                      </Box>
                    </>
                  )}

                  {(values.asset === 'property') &&  (
                    <>
                      <Box >
                        <Box mb={1}>
                          <Typography variant="h4">What is the property address?</Typography>
                        </Box>
                        <TextField size="small" name="address_line_1" label="Address line 1" fullWidth/>
                        <TextField size="small" name="address_line_2" label="Address line 2" fullWidth/>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <TextField size="small" name="city" label="City" fullWidth/> &nbsp;
                          <TextField size="small" name="post_code" label="Post code" fullWidth/>
                        </Box>
                      </Box>

                      <Typography variant="h4">Does this property have a mortgage ?</Typography>
                      <RadioField
                        horizontal
                        name="mortgage"
                        values={{
                          "true": "Yes",
                          "false": "No"
                        }}
                      />

                      <Box mt={1} mb={2}>
                        <Typography variant="h4">Who owns this property?</Typography>
                        <RadioField
                          name="ownership"
                          values={{
                            "myself": "I am the only owner",
                            "shared": "I own it jointly with someone else",
                            "undefined": "I don't know"
                          }}
                        />
                      </Box>

                      <Box mt={2}>
                        <TextField 
                          size="small" 
                          type="number" 
                          name="value" 
                          label="What is the approximate value of this asset?" 
                          fullWidth
                          InputProps={{
                            startAdornment: <span>£</span>,
                          }}
                        />
                      </Box>
                    </>
                  )}

                  {(values.asset === 'gold_and_silver') &&  (
                    <>
                      <Box mb={1}>
                        <Typography variant="h4">Gold</Typography>
                      </Box>
                      <TextField size="small" type="number" name="gold" label="Grams (g)" fullWidth/>
                      <Box mb={1} mt={1}>
                        <Typography variant="h4">Silver</Typography>
                      </Box>
                      <TextField size="small" type="number" name="silver" label="Grams (g)" fullWidth/>
                      
                      <Box mt={2}>
                        <TextField 
                          size="small" 
                          type="number" 
                          name="value" 
                          label="What is the approximate value of this asset?" 
                          fullWidth
                          InputProps={{
                            startAdornment: <span>£</span>,
                          }}
                        />
                      </Box>
                    </>
                  )}

                  {/* Value field for when no asset type is selected */}
                  {!values.asset && (
                    <Box mt={2}>
                      <TextField 
                        size="small" 
                        type="number" 
                        name="value" 
                        label="What is the approximate value of this asset?" 
                        fullWidth
                        InputProps={{
                          startAdornment: <span>£</span>,
                        }}
                      />
                    </Box>
                  )}

                  <Box mb={2}>
                    <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} fullWidth>Save asset</Button>
                  </Box>
                </Form>
                </DialogContent>
              )
            }}
          </Formik>
      </Dialog>
    </div>
  );
}

const mapStateToProps = ({ account, asset }) => ({
  user: account.user,
});

const mapDispatchToProps = dispatch => ({
  createAsset: (formData, postSuccessHook) => dispatch(createAssetAction(formData, postSuccessHook)),
  updateAsset: (assetID, formData, postSuccessHook) => dispatch(updateAssetAction(assetID, formData, postSuccessHook)),
  enqueueSnackbar: (notification) => dispatch(enqueueSnackbarAction(notification, dispatch)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAssetDialog);
