import React from 'react';
import {Link as RouterLink, useParams} from "react-router-dom";
import * as MultiStep from "components/organisms/MultiStep/MultiStep";
import { Box, Link, Typography, Paper } from "@material-ui/core";
import { routes } from "../routes";
import { CheckCircle } from "@material-ui/icons";
import { theme } from "theme/mainTheme";


const PreRegister = () => {
  const { qid } = useParams();

  return (
    <>
      <MultiStep.MultiStep currentPageID={qid} >

        <MultiStep.CustomPage
          pageTitle="A few simple questions"
          eventName="CNW | Bismillah | Viewed 5 simple questions"
          pageID='start'
          nextPageID='location'
          nextButtonText="Get started for free"
          bottomArea={<Box width="100%" textAlign="center" className="font-secondary">Already have an account? <Link component={RouterLink} to={{pathname: routes.login}}>Log in</Link> </Box> }
        >

          <Typography variant="h1" align="center"><Box fontWeight="fontWeightBold" >A few simple questions<br/> to help find your perfect <br/> will, InshaAllah!</Box></Typography>
        </MultiStep.CustomPage>

        <MultiStep.QuestionPage
          pageTitle="Do you live in England or Wales?"
          eventName="CNW | Bismillah | Viewed  4 - Do you live in England or Wales?"
          question="Do you live in England or Wales?"
          pageID='location'
          yesPageID='assets'
          noPageID='rejected'
          prevPageID='start'
        />

        <MultiStep.QuestionPage
          pageTitle="Is everything you own in the UK?"
          eventName="CNW | Bismillah | Viewed  5 - Is everything you own in the UK?"
          question="Is everything you own in the UK?"
          pageID='assets'
          yesPageID='benefits'
          noPageID='benefits'
          prevPageID='location'
          noConfirmationModal={{
            'title': 'Please note!',
            'body': 'We can only account for UK based assets. For assets owned outside of the UK, you will need a will valid in the country where your assets are kept. If you would like to create an Islamic will for your UK based assets only, please continue.'
          }}
        />

        <MultiStep.CustomPage
          pageTitle="What Wahed Wills can do for you"
          eventName="CNW | Bismillah | What Things Wahed Wills can do for you"
          pageID='benefits'
          nextPageID='/register'
          nextButtonText="Continue"
          prevPageID='assets'
          prevButtonText="Previous question" >

          <Typography variant="h1" align="center"><Box fontWeight="fontWeightBold">What Wahed Wills can<br/> do for you</Box></Typography>
          <Paper elevation={2}>
            <Box mt={2} p={3}>
              <Box mb={3}>
                <Box  display="flex"  >
                <CheckCircle style={{color: theme.palette.success.main}}/>&nbsp;
                <strong>Calculate the share of wealth each family member inherits</strong>
                </Box>
                <Box ml={2.9} className="font-secondary">
                  According to the guidelines set out in the Qur'an and Sunnah.
                </Box>
              </Box>
              <Box mb={3}>
                <Box  display="flex" >
                  <CheckCircle style={{color: theme.palette.success.main}}/>&nbsp;
                  <strong>Secure your children's future</strong>
                </Box>
                <Box ml={2.9} className="font-secondary">
                  Appoint guardians if they're under 18.
                </Box>
              </Box>
              <Box mb={3}>
                <Box  display="flex" >
                  <CheckCircle style={{color: theme.palette.success.main}}/>&nbsp;
                 <strong>Leave money</strong>
                </Box>
                <Box ml={2.9} className="font-secondary">
                 Split up to a third of your wealth between a charity, friends and relatives who aren't entitled to inherit from you.
                </Box>
              </Box>
            </Box>
          </Paper>

        </MultiStep.CustomPage>

        <MultiStep.CustomPage
          pageID='rejected'
          nextPageID='/'
          nextButtonText="Home page"
          prevButtonText="Previous question" >
          <Typography variant="h1" align="center"><Box fontWeight="fontWeightBold">Unfortunately we're not quite right for each other.</Box></Typography>
        </MultiStep.CustomPage>

      </MultiStep.MultiStep>
    </>
  )
};

export default PreRegister;

