import * as actions from "redux/actions/auth/types";
import axios from "axios";
import { routes } from 'routes'
import { setActive } from "redux/actions/account";

export const activation = (uid, token) => {
  return {
    types: [
      actions.ACTIVATION_START,
      actions.ACTIVATION_SUCCESS,
      actions.ACTIVATION_ERROR
    ],
    callAPI: () => axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/users/activation/`,{ uid, token }),
    redirectTo: routes.summary,
    successMessages: [{ variant: "success", message: "Your account has been activated" }],
    errorMessagesResponseKeys: ['data.detail'],
    postSuccessHook: (payload, dispatch) => {
      dispatch(setActive())
    },
  }
}


export const activationResend = (email) => {
  return {
    types: [
      actions.ACTIVATION_RESEND_START,
      actions.ACTIVATION_RESEND_SUCCESS,
      actions.ACTIVATION_RESEND_ERROR
    ],
    callAPI: () => axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/users/resend_activation/`,{ email }),
    successMessages: [{ variant: "success", message: "Activation email has been sent" }]
  }
}

