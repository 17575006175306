import { routes } from 'routes'


export const sectionSlug = 'wassiya'

export const sectionSteps = {
  "charity": {
    "label": "Charity",
    "url": routes.wassiyaCharity,
  },
  // Removed Gifts step as per requirements
  // "gifts": {
  //   "label": "Gifts",
  //   "url": routes.wassiyaGifts,
  // },
  "messages": {
    "label": "Additional message",
    "url": routes.wassiyaMessages,
  }
}
