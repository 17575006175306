import React, { useEffect } from 'react';
import _ from 'lodash'
import { Box, Divider, Grid, Link } from "@material-ui/core";
import styled from "styled-components";
import { theme } from "theme/mainTheme";
import { connect } from "react-redux";
import { getConfig as getConfigAction } from "redux/actions/config";

const StyledFooter = styled.footer`
  color: ${theme.palette.grey.A200};
  font-size: 1.6rem;
  ${theme.breakpoints.down("sm")}{
    text-align: center;
  }
  margin-top: 50px;
`
const StyledLink = styled(Link)`
  color: ${theme.palette.grey.A200} !important;
  font-size: ${theme.typography.fontSize.normal};
`

const StyledShare = styled.div`
  text-align: right;
  ${theme.breakpoints.down("sm")}{
    text-align: center;
    margin-top: 10px;
  }
`
const StyledLinkUnderline = styled(Link)`
  color: ${theme.palette.grey.A200} !important;
  text-decoration: underline;
  font-size: 1.6rem;
`

const Footer = ({ user, config, getConfig }) => {
  useEffect(() => {
    if (_.isEmpty(config)) {
      getConfig();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get links from config, default to empty array if not available
  const links = config?.footer_links || [];
  
  // Filter links by position
  const leftLinks = links.filter(link => link.position === "left").sort((a, b) => a.weight - b.weight);
  const rightLinks = links.filter(link => link.position === "right").sort((a, b) => a.weight - b.weight);

  return (
    <StyledFooter>
      <Box component="div" mb={1}>
        <Divider />
      </Box>
      <Box mb={2}>
        <Grid container >
          <Grid item md={6} xs={12} >
            {leftLinks.map((link, index) => (
              <Box component="span" mr={3} key={`left-link-${index}`}>
                <StyledLink target='_blank' href={link.url}>{link.text}</StyledLink>
              </Box>
            ))}
          </Grid>
          {!_.isEmpty(user) &&
          <Grid item md={6} xs={12} >
            {rightLinks.map((link, index) => (
              <StyledShare key={`right-link-${index}`}>
                {link.full_link ? (
                  <StyledLink target='_blank' href={link.url}>{link.text}</StyledLink>
                ) : (
                  <>{link.text}<StyledLinkUnderline target='_blank' href={link.url}>{link.link_text}</StyledLinkUnderline></>
                )}
              </StyledShare>
            ))}
          </Grid>
          }
        </Grid>
      </Box>
    </StyledFooter>
  )
};

const mapStateToProps = ({ account, config }) => ({
  user: account.user,
  config: config,
});

const mapDispatchToProps = dispatch => ({
  getConfig: () => dispatch(getConfigAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer);


