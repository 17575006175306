import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Provider } from 'react-redux';
import MainTemplate from 'templates/MainTemplate';
import { routes } from "routes";
import store, { history } from 'redux/store';
import { ConnectedRouter } from 'connected-react-router'
import Home from "views/Home";
import Login from "views/auth/Login";
import PreRegister from "views/PreRegister";
import Register from "views/auth/Register";
import PasswordReset from "views/auth/PasswordReset";
import PasswordResetConfirm from "views/auth/PasswordResetConfirm";
import ActivationResend from "views/auth/ActivationResend";
import Activation from "views/auth/Activation";
import Summary from "views/Summary";
import Referred from "views/Referred";
import Will from "views/Will";
import Invite from "views/Invite";
import Account from "views/Account";
import PrivateRoute from "routes/PrivateRoute";
import PublicRoute from "routes/PublicRoute";
import AppProvider from "utils/AppProvider";
import isLogin from "utils/isLogin";
import AboutYouNames from "views/will/AboutYou/Names";
import AboutYouContact from "views/will/AboutYou/Contact";
import AboutYouMaritalStatus from "views/will/AboutYou/MaritalStatus";
import AboutYouChildren from "views/will/AboutYou/Children";
import AboutYouParents from "views/will/AboutYou/Parents";
import AboutYouOtherRelatives from "views/will/AboutYou/OtherRelatives";
import ExecutorsIntroduction from "views/will/Executors/Introduction";
import WasiyyahCharity from "views/will/Wassiya/Charity";
import WasiyyahMessages from "views/will/Wassiya/Messages";
import AccountsAndProperty from "views/will/AccountsAndProperty/AccountsAndProperty";
import WillPreview from "views/WillPreview";
import WillEditRoute from "routes/WillEditRoute";


const Root = () => {

  return (
    <Provider store={store}>
      <AppProvider>
        <ConnectedRouter history={history}>
          <Helmet titleTemplate="Wahed Wills - %s" />
            <MainTemplate isLogin={isLogin()}>
              <Route component={ScrollToTop} />
              <Switch>
                <Route exact path={routes.home} component={Home} />
                <PrivateRoute exact path={routes.referred} component={Referred}/>
                <PrivateRoute exact path={routes.summary} component={Summary}/>
                <PrivateRoute exact path={routes.will} component={Will}/>
                <WillEditRoute exact path={routes.aboutYouNames} component={AboutYouNames}/>
                <WillEditRoute exact path={routes.aboutYouContact} component={AboutYouContact}/>
                <WillEditRoute exact path={routes.aboutYouMaritalStatus} component={AboutYouMaritalStatus}/>
                <WillEditRoute exact path={routes.aboutYouChildren} component={AboutYouChildren}/>
                <WillEditRoute exact path={routes.aboutYouParents} component={AboutYouParents}/>
                <WillEditRoute exact path={routes.aboutYouOtherRelatives} component={AboutYouOtherRelatives}/>
                <WillEditRoute exact path={routes.accountAndProperty} component={AccountsAndProperty}/>
                <WillEditRoute exact path={routes.executorsIntroduction} component={ExecutorsIntroduction}/>
                <WillEditRoute exact path={routes.wassiyaCharity} component={WasiyyahCharity}/>
                <WillEditRoute exact path={routes.wassiyaMessages} component={WasiyyahMessages}/>
                <PrivateRoute exact path={routes.invite} component={Invite}/>
                <PrivateRoute exact path={routes.account} component={Account}/>
                <PrivateRoute exact path={routes.willPreview} component={WillPreview}/>
                <PublicRoute exact path={routes.preRegister} component={PreRegister} restricted/>
                <PublicRoute path={routes.login} component={Login} restricted/>
                <PublicRoute path={routes.passwordReset} exact={true} component={PasswordReset}/>
                <PublicRoute path={routes.passwordResetConfirm} component={PasswordResetConfirm}/>
                <PublicRoute path={routes.register} component={Register} restricted/>
                <PublicRoute path={routes.activation} exact={true} component={Activation}/>
                <PublicRoute path={routes.activationResend} component={ActivationResend}/>
                <Redirect to={routes.summary} />
              </Switch>
            </MainTemplate>
        </ConnectedRouter>
    </AppProvider>
  </Provider>
)};

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

export default Root
