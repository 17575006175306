import React from 'react'
import { Box, Hidden, Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx'
import { ReactComponent as Logo } from 'images/logo-wahed.svg';
import { ReactComponent as LogoMobile } from 'images/logo-mobile-wahed.svg';
import _ from "lodash";
import { connect } from "react-redux";
import { Link as RouterLink } from 'react-router-dom';
import { routes } from 'routes'
import MainMenu from "./mainMenu";
import UserMenu from "./userMenu";
import MobileMenu from "./MobileMenu";
import { activationResend as activationResendAction } from "redux/actions/auth/Activation";
import styled from "styled-components";
import { theme } from "theme/mainTheme";


const useStyles = makeStyles(theme => ({

  mainBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 20px 0 20px',
    alignItems: 'center',
    backgroundColor: 'white',
    borderBottom: `2px ${theme.palette.grey["300"]} solid`,
    height: '74px'
  },

  leftBox: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: 'center',
    [theme.breakpoints.down("sm")]: {
      width: '100%',
    }
  },

  rightBox: {
    display: 'flex',
    justifyContent: "flex-end",
    alignItems: 'center',
    paddingRight: '30px'
  },

  activeLink: {
    textDecoration: "none"
  }

}))

const StyledActivationBar = styled.div `
  text-align: center;
  padding: 8px 5px 5px;
  background-color: ${theme.palette.background.highlight};
  
`

const NavBar = ({ router, user, activationResend }) => {
  const classes = useStyles();

  return (
    <Box mb={5}>


      <Box className={clsx(classes.mainBox, 'NavBar')}>
        <Box className={classes.leftBox}>
          <Hidden mdUp>
            <Box  style={{ width: '100%' }} display="flex" >
              <Box>
                <MobileMenu loggedIn={!_.isEmpty(user)} router={router}/>
              </Box>
              <Box flexGrow={1} >
                <Box display="flex" justifyContent="center">
                  <LogoMobile width={85} style={{'margin-left': '-40px', 'top': '5px', 'position': 'absolute', 'z-index': '10'}} />
                </Box>
              </Box>
            </Box>
          </Hidden>
          <Typography component={RouterLink} to={{pathname: routes.summary}} style={{ zIndex: '10'}}  >
            <Hidden smDown >
              <Box display="flex" justifyContent="center">
                <Logo width={180} />
              </Box>
            </Hidden>
          </Typography>
          <Hidden smDown>
            {!_.isEmpty(user)? <MainMenu /> : null }
          </Hidden>
        </Box>

        <Hidden smDown>
          {_.isEmpty(user)?
            <Box className={classes.rightBox}>
              <Link className={!router.location.pathname.includes('/bismillah/')? null: classes.activeLink } component={RouterLink} to={routes.start} >Create new will</Link> &nbsp;&nbsp;&nbsp;
              <Link className={!router.location.pathname.includes(routes.login)? null: classes.activeLink } component={RouterLink} to={{pathname: routes.login}}>Log in</Link>
            </Box>
            :
            <Box >
                <UserMenu user={user}/>
            </Box>
          }
        </Hidden>

      </Box>
      {!_.isEmpty(user) && !user.is_active &&
      <StyledActivationBar onClick={() => activationResend(user.email)}>
         As-salamu alaikum, {user.preferred_name}! We need you to confirm your email address. <Link href='#'>Resend activation email</Link>
      </StyledActivationBar>
      }
    </Box>
  )
}

const mapStateToProps = ({ account, router }) => ({
  user: account.user,
  router
});

const mapDispatchToProps = dispatch => ({
  activationResend: (email) => dispatch(activationResendAction(email)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavBar);

