export const GET_CHARITIES_START = 'GET_CHARITIES_START';
export const GET_CHARITIES_SUCCESS = 'GET_CHARITIES_SUCCESS';
export const GET_CHARITIES_ERROR = 'GET_CHARITIES_ERROR';

export const GET_CHARITY_SHARES_START = 'GET_CHARITY_SHARES_START';
export const GET_CHARITY_SHARES_SUCCESS = 'GET_CHARITY_SHARES_SUCCESS';
export const GET_CHARITY_SHARES_ERROR = 'GET_CHARITY_SHARES_ERROR';

export const SUBMIT_SHARES_START = 'SUBMIT_SHARES_START';
export const SUBMIT_SHARES_SUCCESS = 'SUBMIT_SHARES_SUCCESS';
export const SUBMIT_SHARES_ERROR = 'SUBMIT_SHARES_ERROR';

export const SUBMIT_CHARITY_SHARES_START = 'SUBMIT_CHARITY_SHARES_START';
export const SUBMIT_CHARITY_SHARES_SUCCESS = 'SUBMIT_CHARITY_SHARES_SUCCESS';
export const SUBMIT_CHARITY_SHARES_ERROR = 'SUBMIT_CHARITY_SHARES_ERROR';

export const DELETE_USER_CHARITY_START = 'DELETE_USER_CHARITY_START';
export const DELETE_USER_CHARITY_SUCCESS = 'DELETE_USER_CHARITY_SUCCESS';
export const DELETE_USER_CHARITY_ERROR = 'DELETE_USER_CHARITY_ERROR';

export const GET_RECIPIENT_SHARES_START = 'GET_RECIPIENT_SHARES_START';
export const GET_RECIPIENT_SHARES_SUCCESS = 'GET_RECIPIENT_SHARES_SUCCESS';
export const GET_RECIPIENT_SHARES_ERROR = 'GET_RECIPIENT_SHARES_ERROR';

export const CREATE_RECIPIENT_SHARES_START = 'CREATE_RECIPIENT_SHARES_START';
export const CREATE_RECIPIENT_SHARES_SUCCESS = 'CREATE_RECIPIENT_SHARES_SUCCESS';
export const CREATE_RECIPIENT_SHARES_ERROR = 'CREATE_RECIPIENT_SHARES_ERROR';

export const PARTIAL_UPDATE_RECIPIENT_SHARES_START = 'PARTIAL_UPDATE_RECIPIENT_SHARES_START';
export const PARTIAL_UPDATE_RECIPIENT_SHARES_SUCCESS = 'PARTIAL_UPDATE_RECIPIENT_SHARES_SUCCESS';
export const PARTIAL_UPDATE_RECIPIENT_SHARES_ERROR = 'PARTIAL_UPDATE_RECIPIENT_SHARES_ERROR';

export const DELETE_RECIPIENT_SHARE_START = 'DELETE_RECIPIENT_SHARE_START';
export const DELETE_RECIPIENT_SHARE_SUCCESS = 'DELETE_RECIPIENT_SHARE_SUCCESS';
export const DELETE_RECIPIENT_SHARE_ERROR = 'DELETE_RECIPIENT_SHARE_ERROR';

export const DELETE_RECIPIENT_SHARES_START = 'DELETE_RECIPIENT_SHARES_START';
export const DELETE_RECIPIENT_SHARES_SUCCESS = 'DELETE_RECIPIENT_SHARES_SUCCESS';
export const DELETE_RECIPIENT_SHARES_ERROR = 'DELETE_RECIPIENT_SHARES_ERROR';

export const GET_MESSAGE_START = 'GET_MESSAGE_START';
export const GET_MESSAGE_SUCCESS = 'GET_MESSAGE_SUCCESS';
export const GET_MESSAGE_ERROR = 'GET_MESSAGE_ERROR';

export const SUBMIT_MESSAGE_START = 'SUBMIT_MESSAGE_START';
export const SUBMIT_MESSAGE_SUCCESS = 'SUBMIT_MESSAGE_SUCCESS';
export const SUBMIT_MESSAGE_ERROR = 'SUBMIT_MESSAGE_ERROR';
