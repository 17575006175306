import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import Root from 'views/Root';


if(process.env.REACT_APP_SENTRY_DSN){
  Sentry.init({
    dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
    tracesSampleRate: 1.0,
  });
}


ReactDOM.render(
  // StrictMode is removed to avoid findDOMNode deprecation warnings with Material-UI v4
  // This is a known issue with Material-UI v4 components like Collapse used in Accordion
  <Root />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
