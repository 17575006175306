import React, { useEffect } from 'react';
import _ from 'lodash'
import { Box, Button, Grid, Hidden, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { connect } from 'react-redux';
import { SwapHoriz } from "@material-ui/icons";
import { ChevronRight } from 'react-feather';
import { partialUpdateAccount as partialUpdateAccountAction } from "redux/actions/account";
import SelectField from "components/atoms/SelectField/SelectField";
import AddPersonDialog from "components/molecules/AddPersonDialog/AddPersonDialog";
import {
  getGuardians as getGuardiansAction,
  getPartners as getPartnersAction,
  getPeople as getPeopleAction,
  linkPartner as linkPartnerAction, unlinkGuardian as unlinkGuardianAction,
  unlinkPartner as unlinkPartnerAction
} from "redux/actions/people";
import { enqueueSnackbar as enqueueSnackbarAction } from 'redux/actions/snackbar'
import PersonListItem from "components/molecules/PersonListItem/PersonListItem";
import WillSectionStepper from "components/organisms/Stepper/WillSectionStepper";
import { sectionSlug, sectionSteps } from "views/will/AboutYou/Steps";
import SectionResolver from "utils/SectionsResolver";
import { partialUpdateWill as partialUpdateWillAction } from "redux/actions/wills";
import MobileHelpText from "components/molecules/HelpText/MobileText";
import StandardHelpText from "components/molecules/HelpText/StandardHelpText";


const stepIndex = 2
const [sectionName, section, nextSection] = SectionResolver(sectionSteps, stepIndex)

const MaritalStatus = (
  { user,
    people,
    partners,
    getPeople,
    getPartners,
    partialUpdateAccount,
    linkPartner,
    unlinkPartner,
    unlinkGuardian,
    guardians,
    getGuardians,
    will,
    partialUpdateWill,
    enqueueSnackbar
  }) => {

  useEffect(() => {
    getPeople()
    getPartners()
    getGuardians()
  }, [getPeople, getPartners, getGuardians])

  const getPartner = () => {
    const partner =  people.filter((person) => {
      return person.id === partners[0]
    })
    return partner[0]
  }

  const linkPartnerHook = (partnerID) => {
    linkPartner(partnerID)
    if(guardians.includes(partnerID)){
      unlinkGuardian(partnerID)
    }
  }

  const helpText = "" +
    "<p>Some family members are entitled to inherit more from your wealth than others, according to the guidelines set out in the Qur'an and Sunnah.</p>" +
    "<p>These next few sections are important for us to calculate the share each family member inherits.</p>" +
    "<p>We don't need to know the amount of money you have to calculate this.</p>" +
    "<p>The share they each inherit will appear in your letter of wishes attached to your Islamic will.</p>"

  return (
    <>
    <Helmet><title>{section["label"]}</title></Helmet>
    <Grid container direction="row" spacing={5}>
      <Hidden smDown>
        <Grid item sm={12} xs={12} md={4}>
          <WillSectionStepper activeStepIndex={stepIndex} steps={sectionSteps} valid={will.valid[sectionSlug]}/>
        </Grid>
      </Hidden>
      <Grid item sm={12} xs={12} md={4}>
         <Hidden mdUp>
          <Box mb={3}>
            <WillSectionStepper activeStepIndex={stepIndex} steps={sectionSteps} valid={will.valid[sectionSlug]}/>
          </Box>
        </Hidden>
        <Typography variant="h1" align="center">{section["label"]}</Typography>
        <MobileHelpText text={helpText} collapsable={true} />
        <Formik
          initialValues={{
            relationship_details: user.relationship_details,
          }}
          enableReinitialize={true}
          validationSchema={
            yup.object().shape({
              relationship_details: yup.string()
                .label('Marital status')
                .required(),
            })}

          onSubmit={async(values, { setSubmitting }) => {
            if(values.relationship_details === 'married' && _.isEmpty(partners)){
                setSubmitting(false)
                enqueueSnackbar({
                  message: _.isEmpty(people)? "You must add a partner": "You must select a spouse",
                  options: {variant: "error"},
                })
                return false;
            }
            await partialUpdateAccount(values)

            let progress = will.progress
            progress[sectionSlug][sectionName] = true
            await partialUpdateWill(will.id, {'progress': progress})
          }}
        >

          {({isSubmitting, touched, errors, values}) => {
            return (
              <Form>
                <Box mt={5}>
                  <Box mb={1}>
                    <Typography variant="h4">What is your marital status?</Typography>
                  </Box>
                  <SelectField
                    name="relationship_details"
                    label="Marital status"
                    values={{
                      "single": "Single",
                      "married": "Married",
                      "divorced": "Divorced",
                      "widowed": "Widowed"
                    }}
                    fullWidth
                  />

                </Box>
                {values.relationship_details ==='married'?
                  <Box mt={3} >
                  <Box mb={1}>
                    <Typography variant="h4">Who is your spouse?</Typography>
                  </Box>

                     { _.isEmpty(partners) || _.isEmpty(people)?
                    <>
                      {people.map((person) => (
                        <Box mb={1}>
                          <PersonListItem
                            key={person.id}
                            person={person}
                            personType="spouse"
                            selectable={true}
                            onCheckedHook={linkPartnerHook}
                          />
                        </Box>
                      ))}
                        <AddPersonDialog personType="spouse" dialogButtonText="Add spouse" linkPersonAction={linkPartner} />
                    </>
                    :
                    <>
                      <Box mb={1}>
                        <PersonListItem
                          person={getPartner()}
                          selectable={true}
                          personType="spouse"
                          checked={true}
                          onUncheckedHook={unlinkPartner}
                        />
                      </Box>
                      <Button variant="contained" color="secondary" fullWidth onClick={() => unlinkPartner(getPartner().id)}> Change spouse &nbsp;<SwapHoriz/></Button>
                    </>
                  }

                </Box>
                  : null}
                <Box mt={3} mb={3}>
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} fullWidth>Save and continue <ChevronRight size={20}/></Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </Grid>
      <Hidden smDown>
        <Grid item sm={12} xs={12} md={4}>
          <Box mt={10}>
            <StandardHelpText text={helpText}/>
          </Box>
        </Grid>
      </Hidden>
    </Grid>
  </>
  )
};

const mapStateToProps = ({ account, global, people, wills }) => ({
  user: account.user,
  people: people.people,
  partners: people.partners,
  guardians: people.guardians,
  formError: global.formError,
  will: wills.wills[0]
});

const mapDispatchToProps = dispatch => ({
  partialUpdateAccount: (formData) => dispatch(partialUpdateAccountAction(formData, nextSection['url'])),
  partialUpdateWill: (willID, formData) => dispatch(partialUpdateWillAction(willID, formData)),
  linkPartner: (partnerID) => dispatch(linkPartnerAction(partnerID)),
  unlinkPartner: (partnerID) => dispatch(unlinkPartnerAction(partnerID)),
  unlinkGuardian: (guardianID, postSuccessHook) => dispatch(unlinkGuardianAction(guardianID, postSuccessHook)),
  getPeople: () => dispatch(getPeopleAction()),
  getPartners: () => dispatch(getPartnersAction()),
  getGuardians: (postSuccessHook) => dispatch(getGuardiansAction(postSuccessHook)),
  enqueueSnackbar: (notification) => dispatch(enqueueSnackbarAction(notification, dispatch))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaritalStatus);
