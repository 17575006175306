import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({

  spacing: 10,

  palette: {

    background: {
      default: '#f8f8f8',
      highlight: '#ffd32757'
    },

    default: {
      main: '#333'
    },

    primary:  {
      main: '#f8d234',
      dark: '#f8d234'
    },

    secondary: {
      main: '#ffffff',
      dark: '#ffffff'
    },

    action: {
      active: '#6D7577',
    },

    success: {
      main: '#7cc695'
    },

    error: {
      main: '#b00020'
    },

    text: {
      primary: '#26353b',
      muted: '#7E7787'
    },

    link: {
      main: '#26353b',
      primary: '#6D7577'
    }

  },

  typography: {
    fontFamily: 'URW Geometric, sans-serif',
    fontFamilySecondary: 'URW Geometric, sans-serif',
    htmlFontSize: 10,
    button: {
      fontFamily: 'URW Geometric, sans-serif',
      fontWeight: 500,
      textTransform: "none",
      fontSize: '2.0rem',
    },

    textSize: {
      small: '1.3rem',
      normal: '1.8rem',
      large: '2rem',
      xLarge: '2.5rem',
      xxLarge: '3.4rem'
    },

    h4: {
      fontSize: '2rem',
      fontWeight: 700
    },

    h2: {
      fontSize: '3rem',
      fontWeight: 700
    },

    h1:{
      fontSize: '4rem',
      fontWeight: 700
    },

    subtitle1: {
      fontSize: '2rem',
    },

    subtitle2: {
      fontSize: '1.8rem',
    },
    caption: {
      fontSize: '1.4rem',
      fontFamily: 'URW Geometric, sans-serif'
    }

  },
})


theme.overrides = {
  MuiLink: {
    root: {
      color: `${theme.palette.link.main}`,
      textDecoration: "underline"
    },
    underlineHover: {
      textDecoration: "underline",
      '&:hover': {
         textDecoration: "none",
      },
    }
  },
  MuiTooltip: {
    tooltip: {
      fontSize: '1.3rem'
    }
  },
  MuiFormHelperText: {
    root: {
      fontFamily: 'URW Geometric, sans-serif',
      fontSize: '1.4rem'
    }
  },
  MuiInputBase: {
    input: {
      fontSize: '1.8rem',
    }
  },
  MuiOutlinedInput: {
    input: {
      padding: '18px 14px 14px'
    }
  },
  MuiTypography: {
      h1: {
        [theme.breakpoints.down("xs")]: {
          fontSize: "2.8rem"
        }
      }
    }
}
