import React from "react";
import { Box, Checkbox, Typography } from "@material-ui/core";
import Paper from "components/atoms/Paper/Paper";
import AddChildDialog from "components/molecules/AddChildDialog/AddChildDialog";

const ChildListItem = ({child, selectable = false, checked, onCheckedHook = () => {} , onUncheckedHook = () => {}}) => {

  const clickHandler = (e) => {
    if(e.target.checked) onCheckedHook(child.id)
    else  onUncheckedHook(child.id)
  }

  return (
    <>
      <Paper>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h4">{child.full_name}</Typography>
            <Box color="text.disabled">
              <span>Age: {child.age}</span>
            </Box>
            <AddChildDialog
              textButton="Edit"
              child={child}
            />
          </Box>
          {selectable &&
            <Box>
              <Checkbox name="subscribe" color="primary" defaultChecked={checked} onClick={(event) => clickHandler(event)}/>
            </Box>
          }
        </Box>
      </Paper>
    </>
  )
}


export default ChildListItem
